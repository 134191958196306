import React, { useState, useEffect, useRef } from "react";
import { Link, Button, Card, CardHeader, CardBody, CardFooter, Tabs, Tab, Divider } from "@nextui-org/react";

import { useAuth } from "../components/context/auth_context";
import { RequestTrialAccountButton } from "../components/pricing/request_trial_account";
import { RequestOzAllocationButton } from "../components/pricing/request_oz_allocation";
import { CreditPackage } from "../components/pricing/credit_package";
import { SubscriptionPackages } from "../components/pricing/subscription_package";
import { Sidebar } from "../components/sidebar";
import { RedeemTrialAccountCode } from "../components/pricing/redeem_trial_account_code";
import { SubscriptionContactForm } from "../components/pricing/subscription_contact_form";

export function Pricing() {   
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        requestTrialAccount,
        requestTrialAccountLoading,
    } = useAuth()

    return (
        <>
            <Sidebar>
                <Card className="bg-yellow-500 text-black" radius="none">
                    <CardHeader className=" flex flex-col justify-start items-start ">
                        <span className="font-bold">
                            Important!
                        </span>

                        <p className="text-sm">
                            Please note that we currently only accept <span className="font-bold">USDC</span> and <span className="font-bold">USDT</span> via onchain payments through the Ethereum blockchain.
                        </p>
                    </CardHeader>
                </Card>

                <div className="p-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <Card className='bg-background border-small border-divider'>
                            <CardBody>
                                {!signedIn ? (
                                    <>
                                        <p className="text-5xl">Don't have an API key?</p>
                                        <p className="text-default-500">Create an account to get started.</p>
                                        <div className="mt-4">
                                            <Button color="primary" size="sm" as={Link} href="/login">Create Account</Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {apiAccountData.account_type === 'new' && (
                                            <>
                                                <p className="text-5xl">Don't have any credits?</p>
                                                <p className="text-default-500">Activate a trial account or purchase a credit package to get started.</p>
                                                <div className="mt-4">
                                                    <RequestTrialAccountButton />
                                                </div>
                                                {/* <p className="text-xs text-default-500 mt-4">*Subject to advanced screening to prevent abuse.</p> */}
                                                <div className="w-full flex items-center justify-center mt-4">
                                                    <div className="flex-grow">
                                                        <Divider />
                                                    </div>
                                                    <span className="mx-2">or enter activation code</span>
                                                    <div className="flex-grow">
                                                        <Divider />
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <RedeemTrialAccountCode />
                                                </div>
                                            </>
                                        )}
                                        {apiAccountData.account_type === 'trial' && (
                                            <>
                                                <p className="text-5xl">Out of credits?</p>
                                                <p className="text-default-500">Purchase a credit package!</p>
                                                {/* <div className="mt-4">
                                                    <Button color="primary" size="sm" as={Link} href="/upgrade">Upgrade Account</Button>
                                                </div> */}
                                                
                                            </>
                                        )}
                                        {apiAccountData.account_type === 'standard' && (
                                            <>
                                                <p className="text-5xl">Welcome back!</p>
                                                <p className="text-default-500">View your account analytics on the dashboard page!</p>
                                                <div className="mt-4">
                                                    <Button color="primary" size="sm" as={Link} href="/dashboard">Go to Dashboard</Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </CardBody>
                        </Card>

                        <Card className='bg-background border-small border-divider'>
                            <CardBody>
                                <p className="text-5xl">OzDAO member?</p>
                                <p className="text-default-500">Receive your monthly credit allocation.</p>
                                <div className="mt-4">
                                    <RequestOzAllocationButton />
                                </div>
                                {/* <p className="text-xs text-default-500 mt-4">*Monthly allocation of credits for members of OzDAO.</p> */}
                            </CardBody>
                        </Card>
                    </div>

                    {/* {(!signedIn || apiAccountData.account_type === 'new') && (
                        <Card className='bg-background border-small border-divider'>
                            <CardBody>
                                {!signedIn ? (
                                    <>
                                        <p className="text-5xl">Don't have an API key?</p>
                                        <p className="text-default-500">Create an account to get started.</p>
                                        
                                        <div className="mt-4">
                                            <Button color="primary" size="sm" as={Link} href="/login">Create Account</Button>
                                        </div>
                                    </>
                                ) : null}

                                {apiAccountData.account_type === 'new' ? (
                                    <>
                                        <p className="text-5xl">Don't have an API key?</p>
                                        <p className="text-default-500">Create a trial account or buy a credit/subscription package to get started.</p>
                                        
                                        <div className="mt-4">
                                            <RequestTrialAccount />
                                        </div>
                                    </>
                                ) : null}
                            </CardBody>
                        </Card>
                    )} */}

                    <Card className='bg-background border-small border-divider mt-4'>
                        <CardBody>
                            <Tabs aria-label="Options" variant="light">
                                <Tab key="credit" title="Credit">
                                    <CreditPackage />
                                </Tab>
                                {/* <Tab key="subscription" title="Subscription">
                                    <SubscriptionPackages />
                                </Tab> */}
                                <Tab key="subscription" title="Subscription">
                                    <SubscriptionContactForm />
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </div>
            </Sidebar>
        </>
    );
}