import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Divider, Spinner} from "@nextui-org/react";

import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip} from 'recharts';

import { useDashboard } from "../context/dashboard_context";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="bg-black p-2 rounded-lg">
                <p>{data.path} Total: {data.total_count}</p>
                {data.methods.length > 0 && (
                    <>
                        <Divider className="mb-2" />
                        {data.methods.map((method, index) => (
                            <p className="text-sm text-default-500" key={index}>{method.method}: <span>{method.count}</span></p>
                        ))}
                    </>
                )}
            </div>
        );
    }
    return null;
};

export function ReRadar() {   
    const { fetchRadarChart, radarChartDataLoading, radarChartData, radarChartDataMaxCount } = useDashboard();

    useEffect(() => {
        fetchRadarChart()
    }, []);

    // const [radarChartDataLoading, setRadarChartDataLoading] = useState(false)
    // const [radarChartData, setRadarChartData] = useState([])
    // const [radarChartDataMaxCount, setRadarChartDataMaxCount] = useState(0)

    // useEffect(() => {
    //     setRadarChartDataLoading(true)

    //     fetch('/api/get_radar_data', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ 
    //             // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
    //             // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
    //             // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
    //             // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
    //             // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
    //             wallet_address: address
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data)
    //         if (data.data) {
    //             setRadarChartData(data.data)
    //             setRadarChartDataMaxCount(Math.max(...radarChartData.map(item => item.total_count)))
    //         } else {
    //             console.log('no data...')
    //             setRadarChartDataMaxCount(0)
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error when fetching radar data:', error);
    //         setRadarChartDataLoading(false)
    //     })
    //     .finally(() => {
    //         // setVerifyTelegramUsernameLoading(false)
    //         console.log('finished')
    //         setRadarChartDataLoading(false)
    //     });
    // }, []);
    
    return (
        <>
            <Card className="w-full h-72 bg-background border-small border-divider">
                <CardHeader>
                    <p>Total Usage by Endpoint</p>
                </CardHeader>
                <CardBody className="flex justify-center items-center p-4">
                    {radarChartDataLoading ? (
                        <Spinner />
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            {!radarChartData.every(item => item.total_count === 0) ? (
                                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="path" className="text-xs" />
                                    <PolarRadiusAxis angle={30} domain={[0, radarChartDataMaxCount]} />
                                    <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                                    <Radar name="Requests" dataKey="total_count" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                    <Legend align="left" verticalAlign="top" />
                                </RadarChart>
                            ) : (
                                <div className="flex justify-center">
                                    <span className="text-default-500">No data to display.</span>
                                </div>
                            )}
                        </ResponsiveContainer>
                    )}
                </CardBody>
            </Card>
        </>
    );
}