import React, { useState, useEffect } from "react";
import { Button, Input, Divider, Link } from "@nextui-org/react";

import { useAuth } from "../context/auth_context";

export function RedeemTrialAccountCode() {
    const { 
        redeemTrialAccount,
        redeemTrialAccountLoading,
        redeemTrialAccountCode,
        setRedeemTrialAccountCode
    } = useAuth();

    return (
        <>
            <div className="flex gap-2">
                <Input 
                    variant="bordered" 
                    type="text" 
                    size="sm" 
                    // label="Activation Code" 
                    value={redeemTrialAccountCode}
                    onChange={(e) => setRedeemTrialAccountCode(e.target.value)}
                    placeholder="01110100 01101111 01110100 01101111"
                />
                <Button color="primary" size="sm" isLoading={redeemTrialAccountLoading} isDisabled={redeemTrialAccountLoading} onClick={() => redeemTrialAccount()}>{redeemTrialAccountLoading ? null : "Submit"}</Button>
            </div>
            <span className="text-xs text-default-500">
                For inquiries, contact <Link className="text-xs" href="https://t.me/wavelength777" isExternal showAnchorIcon anchorIcon={' ↗'}>@wavelength777</Link> on Telegram.
            </span>
        </>
    );
}