import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Divider, Spinner} from "@nextui-org/react";

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, ComposedChart, Line, ResponsiveContainer } from 'recharts';

import { useDashboard } from "../context/dashboard_context";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const formattedDate = new Date(data.request_date).toLocaleDateString('en-US', {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric'
        });
        return (
            <div className="bg-black p-2 rounded-lg text-white">
                <p>Requests: {data.request_count}</p>
                <p className="text-xs text-default-500">Date: {formattedDate}</p>
            </div>
        );
    }
    return null;
};

// const calculateTrendLine = (data) => {
//     const n = data.length;
//     const sumX = data.reduce((acc, point, index) => acc + index, 0);
//     const sumY = data.reduce((acc, point) => acc + point.request_count, 0);
//     const sumXY = data.reduce((acc, point, index) => acc + index * point.request_count, 0);
//     const sumXX = data.reduce((acc, point, index) => acc + index * index, 0);
  
//     const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
//     const intercept = (sumY - slope * sumX) / n;
  
//     return data.map((point, index) => ({
//         ...point,
//         trend: Math.max(0, slope * index + intercept) // Ensure trend value does not go below zero
//     }));
// };

export function ReBar() {   
    const { fetchBarChart, barChartDataLoading, barChartData } = useDashboard();

    useEffect(() => {
        fetchBarChart()
    }, []);

    // const [barChartDataLoading, setBarChartDataLoading] = useState(false)
    // const [barChartData, setBarChartData] = useState([])

    // useEffect(() => {
    //     setBarChartDataLoading(true)

    //     fetch('/api/get_bar_data', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ 
    //             // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
    //             // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
    //             // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
    //             // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
    //             // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
    //             // wallet_address: '0x8a0120a7d28b2b2c32d3594ec94b1160dbb3ffc8'
    //             wallet_address: address
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data)
    //         // if (data.data) {
    //         //     setBarChartData(data.data)
    //         // } else {
    //         //     console.log('no data...')
    //         //     setBarChartData(null)
    //         // }
    //         if (data.data) {
    //         const trendData = calculateTrendLine(data.data);
    //             setBarChartData(trendData);
    //         } else {
    //             setBarChartData([]);
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error verifying telegram username:', error);
    //         setBarChartDataLoading(false)
    //     })
    //     .finally(() => {
    //         // setVerifyTelegramUsernameLoading(false)
    //         console.log('finished')
    //         setBarChartDataLoading(false)
    //     });
    // }, []);

    const xAxisTickFormatter = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric'
        });
    };
    
    return (
        <>
            <Card className="w-full h-72 mt-4 bg-background border-small border-divider">
                <CardHeader>
                    <p>Requests per Day</p>
                </CardHeader>
                <CardBody className="flex justify-center items-center p-4">
                    {barChartDataLoading ? (
                        <Spinner />
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            {/* <BarChart data={barChartData}>
                                <XAxis dataKey="request_date" tickFormatter={xAxisTickFormatter} />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="request_count" fill="#8884d8" />
                                {barChartData.length > 14 && (
                                    <Brush dataKey="request_date" height={30} stroke="#8884d8" startIndex={Math.max(0, barChartData.length - 14)} />
                                    // <Brush dataKey="request_date" height={30} stroke="#8884d8" fill="#000" startIndex={Math.max(0, barChartData.length - 14)} />
                                )}
                            </BarChart> */}

                            {barChartData.length > 0 ? (
                                <ComposedChart data={barChartData}>
                                    <XAxis dataKey="request_date" tickFormatter={xAxisTickFormatter} />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="request_count" fill="#8884d8" />
                                    <Line type="monotone" dataKey="trend" stroke="#ff7300" dot={false} />
                                    {barChartData.length > 14 && (
                                        <Brush dataKey="request_date" height={30} stroke="#8884d8" startIndex={Math.max(0, barChartData.length - 14)} />
                                    )}
                                </ComposedChart>
                            ) : (
                                <div className="flex justify-center">
                                    <span className="text-default-500">No data to display.</span>
                                </div>
                            )}

                        </ResponsiveContainer>
                    )}
                </CardBody>
            </Card>
        </>
    );
}