import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import {Card, CardBody, CardFooter, CardHeader, Link, Spinner} from "@nextui-org/react";

import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, getKeyValue, Button, Chip} from "@nextui-org/react";

import { useDashboard } from "../context/dashboard_context";
// import { useAuth } from "../context/auth_context";

export function TransactionHistory() {
    // const { address, truncateAddress, truncateTelegram, telegramUserData, apiAccountData, signedIn } = useAuth();
    const { fetchTransactionHistory, transactionHistoryLoading, transactionHistory } = useDashboard();

    useEffect(() => {
        fetchTransactionHistory()
    }, []);

    // const [transactionHistoryLoading, setTransactionHistoryLoading] = useState(false)
    // const [transactionHistory, setTransactionHistory] = useState({
    //     total_eth_spent: 0,
    //     total_usd_equivalent: 0,
    //     transactions: []
    // });

    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "blockNumber",
        direction: "descending",
    });

    // useEffect(() => {
    //     setTransactionHistoryLoading(true)
    //     console.log('fetching for ', address)

    //     fetch('/api/get_transaction_history', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             wallet_address: address
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log('RECEIVED TX HISTORY DATA', data)
    //         if (data.data) {
    //             setTransactionHistory(data.data)
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error when fetching transaction history:', error);
    //         setTransactionHistoryLoading(false)
    //     })
    //     .finally(() => {
    //         // setVerifyTelegramUsernameLoading(false)
    //         console.log('finished')
    //         setTransactionHistoryLoading(false)
    //     });
    // }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { 
            year: '2-digit', 
            month: 'numeric', 
            day: 'numeric' 
        }).format(date);
    };

    const columns = [
        { key: "blockNumber", label: "Block Number", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "eth_spent", label: "ETH Spent", sortable: false },
        { key: "usd_equivalent", label: "USD Equivalent", sortable: false },
        { key: "payment_type", label: "Payment Type", sortable: false },
        { key: "transactionHash", label: "Transaction Hash", sortable: false },
    ];

    const rows = transactionHistory ? transactionHistory.transactions.map((tx, index) => ({
        key: index,
        blockNumber: tx.blockNumber,
        transactionHash: tx.transactionHash,
        eth_spent: tx.eth_spent.toFixed(4),
        payment_type: tx.payment_type.toUpperCase(),
        usd_equivalent: `$${tx.usd_equivalent.toFixed(2)}`,
        date: formatDate(tx.time)
    })) : [];

    const sortedItems = useMemo(() => {
        return [...rows].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, rows]);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return sortedItems.slice(start, end);
    }, [page, sortedItems, rowsPerPage]);

    const renderCell = (row, columnKey) => {
        const cellValue = getKeyValue(row, columnKey);

        switch (columnKey) {
            case "transactionHash":
                // return <pre className="text-xs text-default-500">{cellValue}</pre>;
                const formattedHash = `${cellValue.slice(0, 6)}...${cellValue.slice(-4)}`;
                return (
                    <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href={`https://etherscan.io/tx/${cellValue}`}>{formattedHash}</Link>
                )
            case "payment_type":
                const chipColorClass = (() => {
                    switch (cellValue) {
                        case 'ETH':
                            return 'text-white-500';
                        case 'USDC':
                            return 'text-blue-500';
                        case 'USDT':
                            return 'text-green-500';
                        default:
                            return 'text-default-500';
                    }
                })();
                return <Chip size="sm" radius="sm" variant="flat" className={`text-xs ${chipColorClass}`}>{cellValue}</Chip>;
            default:
                return <p className="text-xs text-default-500">{cellValue}</p>;
        }
    };

    const onNextPage = () => {
        setPage((prev) => (prev < Math.ceil(rows.length / rowsPerPage) ? prev + 1 : prev));
    };

    const onPreviousPage = () => {
        setPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    return (
        <>
            <Card className="bg-background border-small border-divider">
                <CardHeader>
                    <p>Transaction History</p>
                </CardHeader>
                <CardBody>
                    {transactionHistoryLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <Table
                                aria-label="Transaction Data Table"
                                isStriped
                                removeWrapper
                                sortDescriptor={sortDescriptor}
                                onSortChange={setSortDescriptor}
                            >
                                <TableHeader columns={columns}>
                                    {column => (
                                        <TableColumn 
                                            key={column.key} 
                                            allowsSorting={column.sortable}
                                        >
                                            {column.label}
                                        </TableColumn>
                                    )}
                                </TableHeader>
                                {/* <TableBody emptyContent={"No rows to display."} items={items}>
                                    {row => (
                                        <TableRow key={row.key}>
                                            {columnKey => (
                                                <TableCell>{renderCell(row, columnKey)}</TableCell>
                                            )}
                                        </TableRow>
                                    )}
                                </TableBody> */}
                                <TableBody emptyContent={"No rows to display."}>
                                    {items.map(row => (
                                        <TableRow key={row.key}>
                                            {columns.map(column => (
                                                <TableCell key={`${row.key}-${column.key}`}>
                                                    {renderCell(row, column.key)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    {transactionHistory.transactions.length > 0 && (
                                        <TableRow>
                                            <TableCell><span className="text-xs text-green-500 ">Total</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><span className="text-xs text-green-500">{transactionHistory.total_eth_spent.toFixed(4)}</span></TableCell>
                                            <TableCell><span className="text-xs text-green-500">${transactionHistory.total_usd_equivalent.toFixed(2)}</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </CardBody>
                {transactionHistory.transactions.length > 0 && (
                    <CardFooter className="flex flex-row mt-4 gap-4 justify-between">
                        <Pagination
                            total={Math.ceil(rows.length / rowsPerPage)}
                            color="primary"
                            page={page}
                            onChange={setPage}
                            className="justify-start"
                            size="sm"
                            // showControls
                        />
                        <div className="flex gap-2 justify-end">
                            <Button
                                size="sm"
                                // variant="flat"
                                color="default"
                                onPress={onPreviousPage}
                                isDisabled={page === 1}
                            >
                                Previous
                            </Button>
                            <Button
                                size="sm"
                                // variant="flat"
                                color="default"
                                onPress={onNextPage}
                                isDisabled={page === Math.ceil(rows.length / rowsPerPage)}
                            >
                                Next
                            </Button>
                        </div>
                    </CardFooter>
                )}
            </Card>
        </>
    );
}