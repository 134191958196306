import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

import { useAuth } from './auth_context';

const DashboardContext = createContext(null);

export const DashboardProvider = ({ children }) => {
    const { address, signedIn } = useAuth();

    // const { showAlert } = useAlert();
    // showAlert(`Failed to switch to the chain: ${result.error.message}`, 'error', 'bottom');

    // DASHBOARD DATA STATES
    const [statCardLoading, setStatCardLoading] = useState(false)
    const [statCardData, setStatCardData] = useState(null)

    const [transactionHistoryLoading, setTransactionHistoryLoading] = useState(false)
    const [transactionHistory, setTransactionHistory] = useState({
        total_eth_spent: 0,
        total_usd_equivalent: 0,
        transactions: []
    });

    const [barChartDataLoading, setBarChartDataLoading] = useState(false)
    const [barChartData, setBarChartData] = useState([])

    const [radarChartDataLoading, setRadarChartDataLoading] = useState(false)
    const [radarChartData, setRadarChartData] = useState([])
    const [radarChartDataMaxCount, setRadarChartDataMaxCount] = useState(0)

    const [treemapDataLoading, setTreemapDataLoading] = useState(false)
    const [treemapData, setTreemapData] = useState([])

    const [requestTableDataLoading, setRequestTableDataLoading] = useState(false)
    const [requestTableData, setRequestTableData] = useState({})

    // const address = '0x100073996ee247aa1d32511ae0d30696b6dcf6d6'

    // main stat cards
    const fetchMainStatCards = () => {
        if (!signedIn) {
            console.log('Not signed in, skipping fetch.');
            return;
        }

        if (!statCardData) {
            console.log('fetching main stat card data')

            setStatCardLoading(true);
    
            fetch('/api/get_main_stat_card_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
                    // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
                    // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
                    // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
                    // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
                    // wallet_address: '0x8a0120a7d28b2b2c32d3594ec94b1160dbb3ffc8'
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.data) {
                    setStatCardData(data.data)
                } else {
                    console.log('no data...')
                    setStatCardData(null)
                }
            })
            .catch(error => {
                console.error('Error verifying telegram username:', error);
                setStatCardLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setStatCardLoading(false)
            });
        }
    };

    // transaction history table
    const fetchTransactionHistory = () => {
        if (!signedIn) {
            // console.log('not signed in, not fetching transaction history table data')
            return
        }

        if (Object.keys(transactionHistory.transactions).length === 0) {
            console.log('fetching transaction history data')

            setTransactionHistoryLoading(true)
            console.log('fetching for ', address)

            fetch('/api/get_transaction_history', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log('RECEIVED TX HISTORY DATA', data)
                if (data.data) {
                    setTransactionHistory(data.data)
                }
            })
            .catch(error => {
                console.error('Error when fetching transaction history:', error);
                setTransactionHistoryLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setTransactionHistoryLoading(false)
            });
        }
    };

    // bar chart
    const fetchBarChart = () => {
        if (!signedIn) {
            // console.log('not signed in, not fetching bar chart data')
            return
        }

        if (barChartData.length === 0) {
            console.log('fetching bar chart data')

            setBarChartDataLoading(true)

            fetch('/api/get_bar_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
                    // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
                    // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
                    // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
                    // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
                    // wallet_address: '0x8a0120a7d28b2b2c32d3594ec94b1160dbb3ffc8'
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                // if (data.data) {
                //     setBarChartData(data.data)
                // } else {
                    
                //     console.log('no data...')
                //     setBarChartData(null)
                // }
                if (data.data) {
                    // const trendData = calculateTrendLine(data.data);
                    // setBarChartData(trendData);
                    const n = data.data.length;
                    const sumX = data.data.reduce((acc, point, index) => acc + index, 0);
                    const sumY = data.data.reduce((acc, point) => acc + point.request_count, 0);
                    const sumXY = data.data.reduce((acc, point, index) => acc + index * point.request_count, 0);
                    const sumXX = data.data.reduce((acc, point, index) => acc + index * index, 0);
                    
                    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
                    const intercept = (sumY - slope * sumX) / n;
                    
                    const trendData = data.data.map((point, index) => ({
                        ...point,
                        trend: Math.max(0, slope * index + intercept) // Ensure trend value does not go below zero
                    }));

                    setBarChartData(trendData);
                } else {
                    setBarChartData([]);
                }
            })
            .catch(error => {
                console.error('Error verifying telegram username:', error);
                setBarChartDataLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setBarChartDataLoading(false)
            });
        }
    };

    // radar chart
    const fetchRadarChart = () => {
        if (!signedIn) {
            // console.log('not signed in, not fetching radar chart data')
            return
        }

        if (radarChartData.length === 0) {
            console.log('fetching radar data')

            setRadarChartDataLoading(true)

            fetch('/api/get_radar_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
                    // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
                    // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
                    // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
                    // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.data) {
                    setRadarChartData(data.data)
                    setRadarChartDataMaxCount(Math.max(...radarChartData.map(item => item.total_count)))
                } else {
                    console.log('no data...')
                    setRadarChartDataMaxCount(0)
                }
            })
            .catch(error => {
                console.error('Error when fetching radar data:', error);
                setRadarChartDataLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setRadarChartDataLoading(false)
            });
        }
    };

    // treemap
    const fetchTreemap = () => {
        if (!signedIn) {
            // console.log('not signed in, not fetching treemap data')
            return
        }

        if (treemapData.length === 0) {
            console.log('fetching treemap data')

            setTreemapDataLoading(true)

            fetch('/api/get_treemap_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
                    // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
                    // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
                    // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
                    // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
                    // wallet_address: '0xe60f7ccb8cf2706cfba3f15fc03ed1c7648a0d75'
                    // wallet_address: '0x2078d4ba138cfd29473662616b777879cfbc6794'
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.data) {
                    setTreemapData(data.data)
                } else {
                    console.log('no data...')
                    setTreemapData(null)
                }
            })
            .catch(error => {
                console.error('Error verifying telegram username:', error);
                setTreemapDataLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setTreemapDataLoading(false)
            });
        }
    };

    // request table
    const fetchRequestTable = () => {
        if (!signedIn) {
            // console.log('not signed in, not fetching request table data')
            return
        }

        if (Object.keys(requestTableData).length === 0) {
            console.log('fetching request table data')

            setRequestTableDataLoading(true)

            fetch('/api/get_request_table_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
                    // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
                    // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
                    // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
                    // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
                    // wallet_address: '0x8a0120a7d28b2b2c32d3594ec94b1160dbb3ffc8'
                    wallet_address: address
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.data) {
                    setRequestTableData(data.data)
                } else {
                    console.log('no data...')
                    setRequestTableData({})
                }
            })
            .catch(error => {
                console.error('Error verifying telegram username:', error);
                setRequestTableDataLoading(false)
            })
            .finally(() => {
                // setVerifyTelegramUsernameLoading(false)
                console.log('finished')
                setRequestTableDataLoading(false)
            });
        }
    };

    return (
        <DashboardContext.Provider value={{ fetchMainStatCards, statCardLoading, statCardData, fetchTransactionHistory, transactionHistoryLoading, transactionHistory, fetchBarChart, barChartDataLoading, barChartData, fetchRadarChart, radarChartDataLoading, radarChartData, radarChartDataMaxCount, fetchTreemap, treemapDataLoading, treemapData, fetchRequestTable, requestTableDataLoading, requestTableData }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => useContext(DashboardContext);
