import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import {Spinner, Link, Listbox, Tooltip, ListboxItem, ListboxSection, ScrollShadow, Button, Card, CardHeader, CardBody, CardFooter, Image, useDisclosure} from "@nextui-org/react";
import {
    Navbar, 
    NavbarBrand, 
    NavbarContent, 
    NavbarItem, 
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem
} from "@nextui-org/react";

import { SiGitbook } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import {Icon} from "@iconify/react";

import { useAuth } from "./context/auth_context";
import { Navbar_ } from "./landing_page/navbar";
import { ProfileAvatar } from "./profile_avatar";
import { apiEndpointInfo } from "./constants/api_endpoint_info";

export function Sidebar({ children }) {
    const location = useLocation();
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        requestTrialAccount,
        requestTrialAccountLoading
    } = useAuth()

    const [selectedKeys, setSelectedKeys] = useState(new Set([location.pathname]));

    useEffect(() => {
        setSelectedKeys(new Set([location.pathname]));
    }, [location.pathname]);

    const isSelected = (key) => selectedKeys.has(key);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    if (isMobile) {
        return (
            <>
                <Navbar_ />

                <div>
                    {children}
                </div>
            </>
        )
    }

    return (
        <>
            <div className="flex h-screen">
                {/* <div className="h-screen bg-content1 text-foreground shadow-sm border-r-small border-divider"> */}
                <div className="h-screen bg-background text-foreground border-r-small border-divider">
                    <nav className="h-full w-72 p-6 flex flex-col">
                        <div className="flex flex-row justify-between">
                            <div className="px-2 flex items-center">
                                <Link href="/">
                                    {/* <Image
                                        alt="toto logo"
                                        height={100}
                                        radius="none"
                                        // src="/t2.png"
                                        // src="/toto_wordart.png"
                                        src="/toto_api_wordart.png"
                                        width={100}
                                    /> */}
                                    <img src="/logos/toto_api_wordart.png" width={100} height={100} />
                                </Link>
                            </div>
                            
                            {signedIn && (
                                <ProfileAvatar />
                            )}
                        </div>

                        <ScrollShadow className="h-full max-h-full py-[4vh]" hideScrollBar orientation="horizontal">
                            <div className="w-full">
                                <Listbox
                                    aria-label="Actions"
                                    // onAction={(key) => alert(key)}
                                    // disallowEmptySelection
                                    // selectionMode="single"
                                    selectedKeys={selectedKeys}
                                    onSelectionChange={setSelectedKeys}
                                >
                                    <ListboxSection title="Navigation">
                                        <ListboxItem
                                            aria-label="dashboard"
                                            key="/dashboard"
                                            href="/dashboard"
                                            description="View account analytics"
                                            startContent={<Icon className="" icon="solar:chart-outline" width={24} />}
                                            className={isSelected("/dashboard") ? "bg-default" : null}
                                        >
                                            <span className="text-md">Dashboard</span>
                                        </ListboxItem>

                                        <ListboxItem 
                                            aria-label="playground"
                                            key="/playground"
                                            href="/playground"
                                            description="Create API requests"
                                            startContent={<Icon className="" icon="solar:dumbbells-outline" width={24} />}
                                            className={isSelected("/playground") ? "bg-default" : null}
                                        >
                                            <span className="text-md">Playground</span>
                                        </ListboxItem>

                                        <ListboxItem 
                                            aria-label="pricing"
                                            key="/pricing"
                                            href="/pricing"
                                            description="View pricing details"
                                            startContent={<Icon className="" icon="solar:hand-money-outline" width={24} />}
                                            className={isSelected("/pricing") ? "bg-default" : null}
                                        >
                                            <span className="text-md">Pricing</span>
                                        </ListboxItem>

                                        <ListboxItem 
                                            aria-label="status"
                                            key="/status"
                                            href="/status"
                                            description="View the status of the API"
                                            startContent={<Icon className="" icon="solar:station-outline" width={24} />}
                                            // className={isSelected("/pricing") ? "bg-default" : null}
                                            isDisabled
                                        >
                                            <span className="text-md">Status</span>
                                        </ListboxItem>
                                    </ListboxSection>
                                </Listbox>
                            </div>
                        </ScrollShadow>

                        {signedIn ? (
                            <>
                                {apiAccountData.account_type === 'new' && (
                                    <Card className="overflow-visible" shadow="sm">
                                        <CardBody className="items-center text-center">
                                            <h3 className="text-medium font-medium">
                                                Welcome New User!
                                                <span aria-label="rocket-emoji" className="ml-2" role="img">
                                                    🚀
                                                </span>
                                            </h3>
                                            <p className="py-4 text-small text-default-500">
                                                Request a trial or purchase a credit/subscription package to get started!
                                            </p>
                                            <Button as={Link} className="w-full" color="primary" href="/pricing">
                                                View Pricing
                                            </Button>
                                        </CardBody>
                                    </Card>
                                )}

                                {apiAccountData.account_type === 'trial' && (
                                    <Card className="overflow-visible" shadow="sm">
                                        <CardBody className="items-center text-center">
                                            <h3 className="text-medium font-medium">
                                                Out of Credits?
                                            </h3>
                                            <p className="py-4 text-small text-default-500">
                                                Check out our credit and subscription packages!
                                            </p>
                                            <Button as={Link} className="w-full" color="primary" href="/pricing">
                                                View Pricing
                                            </Button>
                                        </CardBody>
                                    </Card>
                                )}

                                {/* {apiAccountData.account_type === 'basic' && (
                                    <Card className="overflow-visible" shadow="sm">
                                        <CardBody className="items-center py-5 text-center">
                                            <h3 className="text-medium font-medium text-default-700">
                                                Welcome!
                                            </h3>
                                            <p className="p-4 text-small text-default-500">
                                                View your account analytics on the dashboard page! 
                                            </p>
                                            <Button as={Link} href="/dashboard" className="px-10" color="primary">
                                                Dashboard
                                            </Button>
                                        </CardBody>
                                    </Card>
                                )} */}
                            </>
                        ) : (
                            <Card className="overflow-visible" shadow="sm">
                                <CardBody className="items-center text-center">
                                    <h3 className="text-medium font-medium">
                                        Login or Sign Up
                                    </h3>
                                    <p className="py-4 text-small text-default-500">
                                        Log in or create a new account to access our features!
                                    </p>
                                    <Button as={Link} className="w-full" color="primary" href="/login">
                                        Log In
                                    </Button>
                                </CardBody>
                            </Card>
                        )}

                        <div className="mt-2 flex flex-row justify-between">
                            <div className="space-x-1">
                                <Tooltip content="Twitter">
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://twitter.com/toto_api" isExternal={true} isIconOnly aria-label="Twitter">
                                        <FaXTwitter />
                                    </Button> 
                                </Tooltip>

                                <Tooltip content="Telegram">
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://t.co/KiaAdQq7hV" isExternal={true} isIconOnly aria-label="Telegram">
                                        <FaTelegramPlane />
                                    </Button>
                                </Tooltip>

                                <Tooltip content="Gitbook">
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://docs.totoml.app/" isExternal={true} isIconOnly aria-label="Gitbook">
                                        <SiGitbook />
                                    </Button>
                                </Tooltip>
                            </div>

                            <div>
                                <span className="text-xs text-default-500">v0.0.1</span>
                            </div>
                        </div>
                    </nav>
                </div>
                
                <div className="flex-1 overflow-auto">
                    {children}
                </div>
            </div>
        </>
    );
}