import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Divider, Spinner} from "@nextui-org/react";

// import { RadialBarChart, RadialBar, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';

import { useDashboard } from "../context/dashboard_context";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="bg-black p-2 rounded-lg text-white">
                {/* <p>Requests</p>
                <Divider className="mb-2" />
                <p className="text-sm text-default-500">{payload[0].payload.day}: {payload[0].value}</p> */}

                <p>{payload[0].payload.day}</p>
                <Divider className="mb-2" />
                <p className="text-sm text-default-500">Requests: {payload[0].value}</p>
            </div>
        );
    }
    return null;
};

const COLORS = [
    "#fff7bc", "#fee391", "#fec44f", "#fe9929",
    "#ec7014", "#cc4c02", "#993404", "#662506", "#3b0b0b" // Add a darker color for values above 7000
];

const getColor = (value) => {
    const scale = [1000, 2000, 3000, 4000, 5000, 6000, 7000];
    const index = scale.findIndex((v) => value <= v);
    return index === -1 ? COLORS[COLORS.length - 1] : COLORS[index];
};

const CustomizedContent = ({ x, y, width, height, value, name }) => {
    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    fill: getColor(value),
                    stroke: '#000',
                    strokeWidth: 1,
                    strokeOpacity: 1,
                }}
            />
            {width > 50 && height > 20 ? (
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    textAnchor="middle"
                    fill="#000"
                    fontSize={14}
                >
                    {name}
                </text>
            ) : null}
        </g>
    );
};

export function ReTreemap() {   
    const { fetchTreemap, treemapDataLoading, treemapData } = useDashboard();

    useEffect(() => {
        fetchTreemap()
    }, []);

    // const [treemapDataLoading, setTreemapDataLoading] = useState(false)
    // const [treemapData, setTreemapData] = useState([])

    // useEffect(() => {
    //     setTreemapDataLoading(true)

    //     fetch('/api/get_treemap_data', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ 
    //             // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
    //             // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
    //             // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
    //             // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
    //             // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
    //             // wallet_address: '0xe60f7ccb8cf2706cfba3f15fc03ed1c7648a0d75'
    //             // wallet_address: '0x2078d4ba138cfd29473662616b777879cfbc6794'
    //             wallet_address: address
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data)
    //         if (data.data) {
    //             setTreemapData(data.data)
    //         } else {
    //             console.log('no data...')
    //             setTreemapData(null)
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error verifying telegram username:', error);
    //         setTreemapDataLoading(false)
    //     })
    //     .finally(() => {
    //         // setVerifyTelegramUsernameLoading(false)
    //         console.log('finished')
    //         setTreemapDataLoading(false)
    //     });
    // }, []);
    
    return (
        <>
            <Card className="w-full h-72 bg-background border-small border-divider">
                <CardHeader>
                    <p>Requests by Day of the Week</p>
                </CardHeader>
                <CardBody className="flex justify-center items-center p-4">
                    {treemapDataLoading ? (
                        <Spinner />
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            {treemapData.length > 0 ? (
                                <Treemap
                                    data={treemapData}
                                    dataKey="requests"
                                    content={<CustomizedContent />}
                                >
                                    <Tooltip content={<CustomTooltip />} />
                                </Treemap>
                            ) : (
                                <div className="flex justify-center">
                                    <span className="text-default-500">No data to display.</span>
                                </div>
                            )}
                        </ResponsiveContainer>
                    )}
                </CardBody>
            </Card>
        </>
    );
}