import React, { useState, useEffect } from "react";

import { Button } from "@nextui-org/react";

import { useAuth } from "./context/auth_context";
// export function TelegramLoginWidgetTest({ onAuthSuccess }) {
export function TelegramLoginWidgetTest() {
    const { 
        signUpTelegramUserData,
        setSignUpTelegramUserData
    } = useAuth();

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
        // Check if the Telegram object already exists to avoid reloading the script unnecessarily
        if (window.Telegram && window.Telegram.Login && window.Telegram.Login.auth) {
            setIsScriptLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?7';
        script.async = true;
        script.onload = () => setIsScriptLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleTelegramAuth = () => {
        if (isScriptLoaded && window.Telegram.Login.auth) {
            window.Telegram.Login.auth(
                // ozkeytestbot - 7234924852:AAGnvvXp6i3yUEKx44jtzxYrHOSSjM2vaL8
                // domain: totoml.app

                // ozkeybot - 6625778727
                // domain - ozkey.app
                { bot_id: '7234924852', request_access: true },
                (data) => {
                    if (data) {
                        console.log('Telegram data:', data);
                        // onAuthSuccess(data);
                        setSignUpTelegramUserData(data)
                    } else {
                        console.error('Telegram authorization failed');
                    }
                }
            );
        } else {
            console.error('Telegram script not loaded or auth function not available');
        }
    };

    const getUserDisplayName = (userData) => {
        return userData.username || userData.first_name || userData.last_name || "unknown user";
    };

    return (
        <>
            {signUpTelegramUserData ? (
                <Button size="sm" isDisabled onClick={handleTelegramAuth} className="w-full">
                    {getUserDisplayName(signUpTelegramUserData)}
                </Button>
            ) : (
                <Button size="sm" onClick={handleTelegramAuth} className="w-full">
                    Login With Telegram
                </Button>
            )}
        </>
    );
}