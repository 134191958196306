import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAlert } from './alert_context';

import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useSignMessage, useEnsName, useAccountEffect, useDisconnect, useSwitchChain, useSimulateContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { waitForTransactionReceipt } from 'wagmi/actions';
import { decodeEventLog } from 'viem'
import { getBalance, switchChain, estimateGas } from '@wagmi/core'
import { parseUnits } from 'viem'

import { createConfig, http } from '@wagmi/core'
import { mainnet } from '@wagmi/core/chains'
// import { waitForTransactionReceipt } from 'viem/actions';

const config = createConfig({
    chains: [mainnet],
    transports: {
        [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/mUbvhjKY-o9VpqOYDLqPQlmXqRQCSvxK'),
    },
})

function truncateTelegram(name) {
    if (!name || name.length <= 15) {
        return name; // return the original name if it's too short to truncate
    }
    return `${name.slice(0, 6)}...${name.slice(-6)}`; // Return the truncated address
}

function truncateAddress(address) {
    if (!address || address.length < 10) {
        return address; // return the original address if it's too short to truncate
    }
    return `${address.slice(0, 6)}...${address.slice(-4)}`; // Return the truncated address
}

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { showToast } = useAlert();  

    const { address, isConnecting, isDisconnected, isConnected, chain } = useAccount()
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const { signMessage } = useSignMessage();

    // sign in states
    const [signedIn, setSignedIn] = useState(false);
    const [signedInLoading, setSignedInLoading] = useState(false);

    // verify access state
    const [isVerifyingLoading, setIsVerifyingLoading] = useState(false)

    // api account data states
    const INITIAL_API_ACCOUNT_STATE = {
        telegram_username: null,
        api_key: null,
        is_trial: null,
        credits: null,
        has_bought_subscription: null,
        active_subscriptions: null,
        account_type: null,
        admin: null
    };
    const [apiAccountData, setApiAccountData] = useState(INITIAL_API_ACCOUNT_STATE);

    // create new api account data states
    const [signUpTelegramUserData, setSignUpTelegramUserData] = useState(null)
    // const [signUpTelegramUserData, setSignUpTelegramUserData] = useState(
    //     {
    //         "id": 100,
    //         "first_name": "baboosh",
    //         "username": "BABOOSHKA_SLAYER",
    //         "photo_url": "https://t.me/i/userpic/320/i58h5wccqxw1zL5V0FE4NtskGFSbE8-WYMNax0tMTkmxud04sPFnSc_Ks3FkW5-Y.jpg",
    //         "auth_date": 1720632025,
    //         "hash": "f8216c71c97f2e48b1f0a93ba4398e8a73cd3a69565d8897f250c34f284ddde2"
    //     }
    // )
    const [createNewApiAccountLoading, setCreateNewApiAccountLoading] = useState(false)

    // request trial account states
    const [requestTrialAccountLoading, setRequestTrialAccountLoading] = useState(false)
    // redeem trial account states
    const [redeemTrialAccountLoading, setRedeemTrialAccountLoading] = useState(false)
    const [redeemTrialAccountCode, setRedeemTrialAccountCode] = useState('');

    // subscription contact form states
    const [scfLoading, setScfLoading] = useState(false)
    const [scfTelegramInput, setScfTelegramInput] = useState('')
    const [scfTextarea, setScfTextarea] = useState('')
    const [scfCompanyName, setScfCompanyName] = useState('')

    // const updateApiAccountData = (user_dict) => {
    //     setApiAccountData({
    //         ...apiAccountData,
    //         ...user_dict,
    //         account_type: getAccountType(user_dict)
    //     });
    // };
    const updateApiAccountData = (user_dict) => {
        console.log('Updating API account data:', user_dict); // Debugging line
        const newApiAccountData = {
            ...apiAccountData,
            ...user_dict
        };

        console.log('New API account data:', newApiAccountData); // Debugging line
        setApiAccountData({
            ...newApiAccountData,
            account_type: getAccountType(newApiAccountData)
        });
    };
    
    const getAccountType = (user_dict) => {
        let accountType = 'new';
        if (user_dict.is_trial) {
            accountType = 'trial';
        } else if (user_dict.credits || user_dict.has_bought_subscription) {
            accountType = 'standard';
        }
        console.log('account type', accountType)
        return accountType;
    };

    const resetApiAccountData = () => {
        setApiAccountData(INITIAL_API_ACCOUNT_STATE);
    };

    useEffect(() => {
        console.log('[api account data]', apiAccountData)
    }, [apiAccountData]);

    useAccountEffect({
        onConnect(data) {
            if (data.isReconnected) {
                console.log('reconnected')
                // // verify session
                // // if not verified -> remove access token and disconnect
                const access_token = localStorage.getItem('totoml_access_token');
                console.log(`access_token: ${access_token}`);

                // if not access_token, disconnect?
                if (!access_token) {
                    disconnect()
                    return
                }

                let wallet_address = data.address
                console.log('reconnected address', wallet_address)

                setIsVerifyingLoading(true)

                fetch(`${window.origin}/api/verify_session`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        access_token: access_token,
                        wallet_address: wallet_address
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    let status = data['status'];
                    let message = data['message']
                    if (status === 'success') {
                        console.log('USER DATA FROM VERIFY SESSION', data.user_data);
                        let user_dict = data.user_data
                        console.log(message);
                        updateApiAccountData(user_dict);
                        setSignedIn(true);
                        setIsVerifyingLoading(false)
                    } else {
                        console.log(message);
                        localStorage.removeItem('totoml_access_token');
                        // showAlert(message, 'error', 'bottom')
                        showToast({ type: 'error', message: message, position: 'top-right' })
                        resetApiAccountData()
                        setSignedIn(false);
                        setIsVerifyingLoading(false)
                        disconnect();
                    }
                });
            } else {
                console.log('first time connection', data.address)
            }
        }
    })

    const handleSignIn = () => {
        console.log('authenticating user')

        setSignedInLoading(true)

        let message = `Verifying my wallet\n\n${address}\n\non totoml.app.`
        signMessage({ 
            // message: `Verifying my wallet\n\n${address}\n\non ozkey.app.`,
            message: message
        }, {
            onSuccess(data) {
                console.log('Successfully signed message', data);

                fetch(`${window.origin}/api/login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        signer_address: address, 
                        signature_message: message,
                        signature: data,
                        // origin: window.location.hostname,
                    })
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data)
                    let status = data['status'];
                    let message = data['message']
                    if (status === 'success') {
                        localStorage.setItem('totoml_access_token', data['access_token']);
                        setSignedIn(true);
                        showToast({ type: 'success', message: message, position: 'top-right' })
                        let user_dict = data.user_dict
                        updateApiAccountData(user_dict);
                        setSignedInLoading(false)
                    } else {
                        setSignedIn(false);
                        setSignedInLoading(false)
                        console.log('no api account, show telegram login widget')
                        // showAlert('Not an API user, please login with Telegram', 'error', 'bottom')
                        showToast({ type: 'error', message: message, position: 'top-right' })
                    }
                })
                .catch((error) => {
                    console.log("Fetch error: ", error);
                    setSignedInLoading(false)
                    showToast({ type: 'error', message: 'Error signing in...', position: 'top-right' })
                });
            },
            onError(error) {
                console.log('Error signing message', error);
                setSignedInLoading(false)
            }
        });
    };

    const handleSignOut = () => {
        try {
            fetch(`${window.origin}/api/logout`)
            .then(response => response.json())
            .then(data => {
                if (data.logout) {
                    console.log('Logout successful');
                    localStorage.removeItem('totoml_access_token');
                    resetApiAccountData()
                    showToast({ type: 'success', message: 'Logged out!', position: 'top-right' })
                    setSignedIn(false)
                    disconnect();
                } else {
                    console.error('Logout failed');
                }
            })
            .catch(error => {
                console.error('Error during logout:', error);
            });
        } catch (error) {
            console.error("Error signing out from the wallet modal:", error);
        }
    };
    
    const handleOpen = () => {
        try {
            open();
        } catch (error) {
            console.error("Error opening the wallet modal:", error);
        }
    };

    const handleDisconnect = () => {
        try {
            disconnect();
        } catch (error) {
            console.error("Error disconnecting the wallet modal:", error);
        }
    };

    const createNewApiAccount = () => {
        if (address && signUpTelegramUserData) {
            console.log('[createNewApiAccount] - both address and tg user data', address, signUpTelegramUserData)

            setCreateNewApiAccountLoading(true)

            fetch(`${window.origin}/api/create_new_api_account`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    wallet_address: address, 
                    telegram_user_data: signUpTelegramUserData,
                })
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('[create_new_api_account] - network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('[create_new_api_account]:', data);
                let status = data['status'];
                if (status === 'success') {
                    console.log('new account, creating...');
                    let user_dict = data['user_dict'];
                    let access_token = data['access_token'];
                    updateApiAccountData(user_dict);
                    localStorage.setItem('totoml_access_token', access_token);
                    setSignedIn(true);
                    showToast({ type: 'success', message: 'API account created!', position: 'top-right' });
                    // navigate('/pricing');
                } else {
                    console.log('error', data['message']);
                    showToast({ type: 'error', message: data['message'], position: 'top-right' });
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.toString());
                showToast({ type: 'error', message: '[create_new_api_account] - error, fetch', position: 'top-right' });
            })
            .finally(() => {
                console.log('[create_new_api_account] - fetch attempt finished');
                setCreateNewApiAccountLoading(false);
            });            
        }
    }

    const requestTrialAccount = () => {
        console.log('requesting trial account...')

        setRequestTrialAccountLoading(true)

        fetch(`${window.origin}/api/request_trial_account`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                wallet_address: address,
                telegram_username: apiAccountData.telegram_username
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('[create_new_api_account] - network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log('[request_trial_account]:', data);
            let user_data = data['user_data']
            let status = data['status'];
            let message = data['message']
            if (status === 'success') {
                console.log('created api key');
                updateApiAccountData({
                    ...user_data,
                    is_trial: true,
                    current_credits: user_data['credits'],
                });
                showToast({ type: 'success', message: message, position: 'top-right' });
            } else {
                console.log('error', message);
                showToast({ type: 'error', message: message, position: 'top-right' });
            }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.toString());
            setRequestTrialAccountLoading(false)
            showToast({ type: 'error', message: '[request_trial_account] - error, fetch', position: 'top-right' });
        })
        .finally(() => {
            console.log('[request_trial_account] - fetch attempt finished');
            setRequestTrialAccountLoading(false);
        }); 
    };

    const redeemTrialAccount = () => {
        console.log('redeeming trial account...')

        if (!redeemTrialAccountCode.trim()) {
            showToast({ type: 'error', message: 'Please enter a valid code.', position: 'top-right' });
            return;
        }

        setRedeemTrialAccountLoading(true)

        fetch(`${window.origin}/api/redeem_trial_account`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                code: redeemTrialAccountCode,
                wallet_address: address,
                telegram_username: apiAccountData.telegram_username
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('[create_new_api_account] - network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log('[redeem_trial_account]:', data);
            let status = data['status'];
            let message = data['message']
            if (status === 'success') {
                let user_data = data['user_data']
                console.log('redeemed api trial');
                updateApiAccountData({
                    ...user_data,
                    is_trial: true,
                    current_credits: user_data['credits'],
                });
                showToast({ type: 'success', message: message, position: 'top-right' });
            } else {
                console.log('error', message);
                showToast({ type: 'error', message: message, position: 'top-right' });
            }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.toString());
            setRedeemTrialAccountLoading(false)
            showToast({ type: 'error', message: '[redeem_trial_account] - error, fetch', position: 'top-right' });
        })
        .finally(() => {
            console.log('[redeem_trial_account] - fetch attempt finished');
            setRedeemTrialAccountLoading(false);
        }); 
    };

    const submitScf = () => {
        if (
            (!signedIn && scfTelegramInput.trim() === '') || 
            scfCompanyName.trim() === '' || 
            scfTextarea.trim() === ''
        ) {
            showToast({ type: 'error', message: 'Please finish filling out the form.', position: 'top-right' });
            console.log('empty states, can not submit form')
            return;
        }
        
        console.log('submitting contact form...')

        setScfLoading(true)

        fetch(`${window.origin}/api/subscription_contact`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                telegram_username: signedIn ? apiAccountData.telegram_username : scfTelegramInput,
                company_name: scfCompanyName,
                reason: scfTextarea
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('[create_new_api_account] - network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log('[redeem_trial_account]:', data);
            let status = data['status'];
            let message = data['message']
            if (status === 'success') {
                console.log('submitted contact form');
                setScfTelegramInput('')
                setScfTextarea('')
                setScfCompanyName('')
                showToast({ type: 'success', message: message, position: 'top-right' });
            } else {
                console.log('error', message);
                showToast({ type: 'error', message: message, position: 'top-right' });
            }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.toString());
            setScfLoading(false)
            showToast({ type: 'error', message: '[subscription_contact] - error, fetch', position: 'top-right' });
        })
        .finally(() => {
            console.log('[subscription_contact] - fetch attempt finished');
            setScfLoading(false);
        }); 
    };

    const [requestOzAllocationLoading, setRequestOzAllocationLoading] = useState(false)

    const requestOzAllocation = () => {
        console.log('requesting oz monthly allocation...')

        setRequestOzAllocationLoading(true)

        fetch(`${window.origin}/api/request_oz_allocation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                wallet_address: address,
                telegram_username: apiAccountData.telegram_username
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('[requestOzAllocation] - network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log('[request_trial_account]:', data);
            let user_data = data['user_data']
            let status = data['status'];
            let message = data['message']
            if (status === 'success') {
                console.log('created api key');
                updateApiAccountData({
                    ...user_data,
                    current_credits: user_data['credits'],
                    is_trial: user_data['is_trial'],
                });
                showToast({ type: 'success', message: message, position: 'top-right' });
            } else {
                console.log('error', message);
                showToast({ type: 'error', message: message, position: 'top-right' });
            }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.toString());
            setRequestOzAllocationLoading(false)
            showToast({ type: 'error', message: '[requestOzAllocation] - error, fetch', position: 'top-right' });
        })
        .finally(() => {
            console.log('[requestOzAllocation] - fetch attempt finished');
            setRequestOzAllocationLoading(false);
        }); 
    };


    // =========================== PURCHASE FUNCTIONS ===========================

    const TOTO_ETH_MULTISIG = '0x2E4176269362dd478B37823a734E62304a33cC0b'
    const CONTRACT_ADDRESSES = {
        usdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    };
    const USDC_TRANSFER_ABI_FUNCTION = [
        {
            type: 'function',
            name: 'transfer',
            stateMutability: 'payable',
            inputs: [
                { name: 'to', type: 'address' },
                { name: 'value', type: 'uint256' },
            ],
            outputs: [{ type: 'bool' }],
        }
    ]
    const USDT_TRANSFER_ABI_FUNCTION = [
        {
            type: 'function',
            name: 'transfer',
            stateMutability: 'nonpayable',
            inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' }
            ],
            outputs: []
        }
    ]

    const [creditPurchaseLoading, setCreditPurchaseLoading] = useState(false)
    const [subscriptionPurchaseLoading, setSubscriptionPurchaseLoading] = useState(false)
    
    const transferEventLogSelector = (transactionReceipt, items) => {
        if (!transactionReceipt?.logs || transactionReceipt.logs.length === 0) return null;
        
        const events = transactionReceipt.logs.map((log) => {
            try {
                return decodeEventLog({
                    data: log.data,
                    topics: log.topics,
                    eventName: 'Transfer',
                    abi: items.payment_type === 'usdc' ? USDC_TRANSFER_ABI_FUNCTION : USDT_TRANSFER_ABI_FUNCTION,
                });
            } catch (e) {
                return null;
            }
        });
        
        return events.filter((event) => event !== null).find((event) => event?.eventName === 'Transfer')?.args;
    }

    const { data: hash, isPending: writePending, writeContractAsync } = useWriteContract();

    const handleCreditPurchase = async (items) => {
        setCreditPurchaseLoading(true)
        console.log('handling credit purchase', items)
        let contractAddress = CONTRACT_ADDRESSES[items.payment_type];
        let price = items.price
        // let price = 0.01
        let balance = await balanceCheck(contractAddress);
        console.log(`${items.payment_type.toUpperCase()} balance`, balance);
        console.log('package cost', price)
        if (balance < price) {
            console.log("can't buy: not enough usdc");
            // showAlert("Insufficient USDC balance", 'error', 'bottom');
            showToast({ type: 'error', message: `Insufficient ${items.payment_type.toUpperCase()} balance`, position: 'top-right' });
            setCreditPurchaseLoading(false);
            return;
        }

        try {
            const hash = await writeContractAsync({
                address: contractAddress,
                abi: items.payment_type === 'usdc' ? USDC_TRANSFER_ABI_FUNCTION : USDT_TRANSFER_ABI_FUNCTION,
                functionName: 'transfer',
                args: [
                    TOTO_ETH_MULTISIG,
                    parseUnits(price.toString(), 6)
                ]
            })
    
            const receipt = await waitForTransactionReceipt(config, {hash});
    
            const transferEventReturnData = transferEventLogSelector(receipt, items);
            console.log('[transferEventReturnData]', transferEventReturnData)
    
            if (receipt) {
                const dataWithStrings = serializeBigInts(receipt);
                let txn_hash = dataWithStrings.transactionHash

                console.log('receipt', receipt)
                fetch(`${window.origin}/api/write_purchase`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        wallet_address: address,
                        telegram_username: apiAccountData.telegram_username,
                        transaction_hash: hash,
                        package: items,
                        receipt: dataWithStrings
                    })
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('[create_new_api_account] - network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('writing purchase data', data)
                    let user_data = data['user_data']
                    let status = data['status']
                    let message = data['message']
                    if (status === 'success') {
                        console.log('writing success')
                        let user_dict = data.user_data
                        console.log('[WRITE RETURN - user_dict]:', user_dict);
                        // updateApiAccountData(user_dict);
                        updateApiAccountData({
                            ...user_dict,
                            current_credits: user_data['credits'],
                            is_trial: false
                        });
                        setCreditPurchaseLoading(false)
                        showToast({ type: 'error', message: message, position: 'top-right' });
                    } else {
                        console.log('writing error', message)
                        setCreditPurchaseLoading(false);
                        showToast({ type: 'error', message: message, position: 'top-right' });
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error.toString());
                    setCreditPurchaseLoading(false)
                    showToast({ type: 'error', message: '[writing purchase ue] - error, fetch', position: 'top-right' });
                })
                .finally(() => {
                    console.log('[writing purchase ue] - fetch attempt finished');
                    setCreditPurchaseLoading(false);
                }); 
            }

        } catch (error) {
            console.log('error', error)
            setCreditPurchaseLoading(false);
            if (error?.message.includes("User rejected the request")) {
                showToast({ type: 'error', message: 'User rejected the request.', position: 'top-right' });
            } else {
                showToast({ type: 'error', message: error.message, position: 'top-right' });
            }
        }
    }

    const handleSubscriptionPurchase = async (items) => {
        setSubscriptionPurchaseLoading(true)
        console.log('handling subscription purchase', items)
        let contractAddress = CONTRACT_ADDRESSES[items.payment_type];
        let price = items.route_pricing.price
        // let price = 0.01
        let balance = await balanceCheck(contractAddress);
        console.log(`${items.payment_type.toUpperCase()} balance`, balance);
        console.log('package cost', price)
        if (balance < price) {
            console.log("can't buy: not enough usdc");
            // showAlert("Insufficient USDC balance", 'error', 'bottom');
            showToast({ type: 'error', message: `Insufficient ${items.payment_type.toUpperCase()} balance`, position: 'top-right' });
            setSubscriptionPurchaseLoading(false);
            return;
        }

        try {
            const hash = await writeContractAsync({
                address: contractAddress,
                abi: items.payment_type === 'usdc' ? USDC_TRANSFER_ABI_FUNCTION : USDT_TRANSFER_ABI_FUNCTION,
                functionName: 'transfer',
                args: [
                    TOTO_ETH_MULTISIG,
                    parseUnits(price.toString(), 6)
                ]
            })
    
            const receipt = await waitForTransactionReceipt(config, {hash});
    
            const transferEventReturnData = transferEventLogSelector(receipt, items);
            console.log('[transferEventReturnData]', transferEventReturnData)
    
            if (receipt) {
                const dataWithStrings = serializeBigInts(receipt);
                let txn_hash = dataWithStrings.transactionHash

                console.log('receipt', receipt)
                fetch(`${window.origin}/api/write_purchase`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        wallet_address: address,
                        telegram_username: apiAccountData.telegram_username,
                        transaction_hash: hash,
                        package: items,
                        receipt: dataWithStrings
                    })
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('[writing subscription purchase] - network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('writing subscription purchase data', data)
                    let user_data = data['user_data']
                    let status = data['status']
                    let message = data['message']
                    if (status === 'success') {
                        console.log('writing subscription success')
                        let user_dict = data.user_data
                        console.log('[WRITE subscription RETURN - user_dict]:', user_dict);
                        // updateApiAccountData(user_dict);
                        updateApiAccountData({
                            ...user_dict,
                            is_trial: false,
                            has_bought_subscription: true,
                            active_subscriptions: user_data['active_subscriptions']
                        });
                        setSubscriptionPurchaseLoading(false)
                        showToast({ type: 'error', message: message, position: 'top-right' });
                    } else {
                        console.log('writing error', message)
                        setSubscriptionPurchaseLoading(false);
                        showToast({ type: 'error', message: message, position: 'top-right' });
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error.toString());
                    setSubscriptionPurchaseLoading(false)
                    showToast({ type: 'error', message: '[writing purchase ue] - error, fetch', position: 'top-right' });
                })
                .finally(() => {
                    console.log('[writing purchase ue] - fetch attempt finished');
                    setSubscriptionPurchaseLoading(false);
                }); 
            }

        } catch (error) {
            console.log('error', error)
            setSubscriptionPurchaseLoading(false);
            if (error?.message.includes("User rejected the request")) {
                showToast({ type: 'error', message: 'User rejected the request.', position: 'top-right' });
            } else {
                showToast({ type: 'error', message: error.message, position: 'top-right' });
            }
        }
    }

    const balanceCheck = async (contractAddress) => {
        try {
            const result = await getBalance(config, {
                address: address,
                token: contractAddress,
            });
            const balance = parseFloat(result.formatted);
            return balance;
        } catch (error) {
            console.error("Error fetching balance:", error);
            return 0.0;
        }
    };

    function serializeBigInts(obj) {
        return JSON.parse(JSON.stringify(obj, (key, value) =>
            typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged
        ));
    }

    // =========================== PLAYGROUND FUNCTIONS ===========================

    const [playgroundApiKey, setPlaygroundApiKey] = useState(""); 
    const [playgroundApiKeyValid, setPlaygroundApiKeyValid] = useState(null);

    const checkPlaygroundApiKey = () => {
        fetch(`${window.origin}/api/check_playground_api_key`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                api_key: playgroundApiKey
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((data) => {
            console.log(data)
            if (data.valid === true) {
                setPlaygroundApiKeyValid(true)
            } else {
                setPlaygroundApiKeyValid(false)
            }
        })
        .catch((error) => {
            console.log("Fetch error: ", error);
            setPlaygroundApiKeyValid(false)
        });
    }

    const resetPlaygroundApiKey = () => {
        setPlaygroundApiKey('')
        setPlaygroundApiKeyValid(null)
    }

    return (
        <AuthContext.Provider 
            value={{ 
                address, truncateAddress, truncateTelegram,
                signedIn, signedInLoading,
                isVerifyingLoading,
                apiAccountData,
                handleSignIn, handleSignOut, handleOpen, handleDisconnect,
                createNewApiAccount, createNewApiAccountLoading,
                signUpTelegramUserData, setSignUpTelegramUserData,
                requestTrialAccount, requestTrialAccountLoading,
                redeemTrialAccount, redeemTrialAccountLoading, redeemTrialAccountCode, setRedeemTrialAccountCode,
                handleCreditPurchase, creditPurchaseLoading,
                handleSubscriptionPurchase, subscriptionPurchaseLoading,
                playgroundApiKey, setPlaygroundApiKey, playgroundApiKeyValid, checkPlaygroundApiKey, resetPlaygroundApiKey,
                requestOzAllocation, requestOzAllocationLoading, setRequestOzAllocationLoading,
                submitScf,
                scfLoading, setScfLoading,
                scfTelegramInput, setScfTelegramInput,
                scfTextarea, setScfTextarea,
                scfCompanyName, setScfCompanyName,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
