import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, Button, Card, CardHeader, CardBody, CardFooter, Tabs, Tab, Divider } from "@nextui-org/react";

import { useAuth } from "../components/context/auth_context";
import { TelegramLoginWidgetTest } from "../components/telegram_login_widget_test";
import { Sidebar } from "../components/sidebar";

export function Login() {  
    const navigate = useNavigate();
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData
    } = useAuth()

    const [selected, setSelected] = useState('login')

    useEffect(() => {
        if (signedIn && apiAccountData) {
            console.log('SIGNED IN')
            // if account type is new navigate to /pricing, else dashboard
            if (apiAccountData.account_type === 'new') {
                console.log('NEW ACCOUNT')
                navigate('/pricing')
            } else {
                console.log('TRIAL / BASIC ACCOUNT')
                navigate('/dashboard')
            }
        }
        // if (signedIn && apiAccountData) {
        //     navigate('/pricing')
        // }
    }, [signedIn]);
    
    return (
        <>
            <Sidebar>
                {signedIn ? (
                    null
                ) : (
                    <div className="p-6">
                        <Card className="bg-background border-small border-divider">
                            <CardHeader>
                                <span>Account Tiers</span>
                            </CardHeader>
                            <CardBody>
                                <p><span className="font-bold">New:</span> <span className="text-sm text-default-500">Newly registered account</span></p>
                                {/* <p className="text-default-500 text-xs">Access: pricing, playground (get_userid)</p> */}
                                <p><span className="font-bold">Trial:</span> <span className="text-sm text-default-500">Newly registered account + successfully requested trial</span></p>
                                <p><span className="font-bold">Standard:</span> <span className="text-sm text-default-500">Account that has bought a credit/subscription package</span></p>
                            </CardBody>
                            <CardFooter>
                                <p className="text-xs text-yellow-500">YOU WILL NOT BE ABLE TO ACTIVATE A TRIAL ACCOUNT WITH A FRESH WALLET</p>
                            </CardFooter>
                        </Card>

                        <div className="flex justify-center mx-auto mt-4">
                            <Card className="w-full max-w-[400px] bg-background border-small border-divider">
                                <CardBody>
                                    <Tabs
                                        fullWidth
                                        size="md"
                                        aria-label="Tabs form"
                                        selectedKey={selected}
                                        onSelectionChange={setSelected}
                                        variant="light"
                                    >
                                        <Tab key="login" title="Login">
                                            <div>
                                                {address ? (
                                                    <>
                                                        <div>
                                                            <Button className="w-full" size="sm" onClick={() => handleOpen()}>
                                                                {truncateAddress(address)}
                                                            </Button>
                                                            <Link href="#" onClick={() => handleDisconnect()} className="text-xs text-default-500 flex justify-end mt-2">Disconnect</Link>
                                                        </div>

                                                        <div className="mt-2">
                                                            {address ? (
                                                                <Button className="w-full" size="sm" color="primary" isDisabled={signedInLoading} isLoading={signedInLoading} onClick={() => handleSignIn()}>
                                                                    {signedInLoading ? null : "Sign In"}
                                                                </Button>
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button className="w-full" size="sm" onClick={() => handleOpen()}>Connect Wallet</Button>
                                                    </>
                                                )}
                                            </div>

                                            <Divider className="mt-4" />

                                            <div className="mt-4">
                                                <p className="text-center text-small">
                                                    Need to create an account?{" "}
                                                    <Link size="sm" href="#" onClick={() => setSelected("sign-up")}>
                                                        Sign up
                                                    </Link>
                                                </p>
                                            </div>
                                        </Tab>
                                        <Tab key="sign-up" title="Sign Up">
                                            <div>
                                                {address ? (
                                                    <>
                                                        <div>
                                                            <Button className="w-full" size="sm" onClick={() => handleOpen()}>
                                                                {truncateAddress(address)}
                                                            </Button>
                                                            <Link href="#" onClick={() => handleDisconnect()} className="text-xs text-default-500 flex justify-end mt-2">Disconnect</Link>
                                                        </div>
                                                        <div className="mt-2">
                                                            <TelegramLoginWidgetTest />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button className="w-full" size="sm" onClick={() => handleOpen()}>Connect Wallet</Button>
                                                    </>
                                                )}
                                            </div>
                                            
                                            <div className="mt-2">
                                                {address && signUpTelegramUserData ? (
                                                    <Button className="w-full" size="sm" color="primary" isDisabled={createNewApiAccountLoading} isLoading={createNewApiAccountLoading} onClick={() => createNewApiAccount()}>
                                                        {createNewApiAccountLoading ? null : "Create Account"}
                                                    </Button>
                                                ) : (
                                                    null
                                                )}
                                            </div>

                                            <Divider className="mt-4" />

                                            <div className="mt-4">
                                                <p className="text-center text-small">
                                                    Already have an account?{" "}
                                                    <Link size="sm" href="#" onClick={() => setSelected("login")}>
                                                        Login
                                                    </Link>
                                                </p>
                                                <p className="text-xs text-yellow-500 mt-4">IF YOU WANT TO ACTIVATE A TRIAL ACCOUNT, DO NOT SIGN UP WITH A FRESH WALLET. PLEASE MAKE SURE THE WALLET HAS SIGNIFICANT ACTIVITY, OTHERWISE THE TRIAL REQUEST WILL BE AUTOMATICALLY REJECTED.</p>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                )}
            </Sidebar>
        </>
    );
}