export const apiEndpointInfo = [
    {
        endpoint: "metadata",
        description: "Handles user metadata including counts, tweets, and usernames.",
        routes: [
            { name: "get_follower_count", method: "POST", disabled: false, since: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/metadata/get_follower_count', description: 'Get follower count of a user' },
            { name: "get_latest_metadata", method: "POST", disabled: false, since: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/metadata/get_latest_metadata', description: 'Get most recent valid metadata for a given user' },
            { name: "get_latest_tweets", method: "POST", disabled: false, since: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_latest_tweets', description: 'Get latest tweets of a user' },
            { name: "get_metadata_history", method: "POST", disabled: false, since: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/metadata/get_metadata_history', description: 'Get metadata history for a given user' },
            { name: "get_past_usernames", method: "POST", disabled: false, since: false, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/metadata/get_past_usernames', description: 'Get all previous usernames for a given user' },
            { name: "get_tweets", method: "POST", disabled: true, since: false, popular: false, creditValue: 0, free: false, url: 'https://toto.oz.xyz/api/metadata/get_tweets', description: 'Get tweets for a given user' },
            { name: "get_userid", method: "POST", disabled: false, since: false, popular: false, creditValue: 0, free: true, url: 'https://toto.oz.xyz/api/metadata/get_userid', description: 'Get Twitter userid for a given user' },
        ]
    },
    {
        endpoint: "graph",
        description: "Provides data about social graphs like followers and following.",
        routes: [
            { name: "get_followers", method: "POST", disabled: false, since: true, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/graph/get_followers', description: 'Get followers of a user' },
            { name: "get_following", method: "POST", disabled: false, since: true, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/graph/get_following', description: 'Get following of a user' },
        ]
    },
    {
        endpoint: "lists",
        description: "Provides details pertaining to Twitter lists.",
        routes: [
            { name: "get_latest_list_tweets", method: "POST", disabled: true, since: false, popular: false, creditValue: 0, free: false, url: 'https://toto.oz.xyz/api/lists/get_latest_list_tweets', description: 'Get latest tweets from a list' },
            { name: "get_list_members", method: "POST", disabled: true, since: false, popular: false, creditValue: 0, free: false, url: 'https://toto.oz.xyz/api/lists/get_list_members', description: 'Get list members' },
        ]
    },
    {
        endpoint: "alpha",
        description: "Access to alpha features such as classification and historical scores.",
        routes: [
            { name: "get_classification", method: "POST", disabled: false, since: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/alpha/get_classification', description: 'Get classifications for given username(s)' },
            { name: "get_score_history", method: "POST", disabled: false, since: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/alpha/get_score_history', description: 'Get score history for a given user' },
        ]
    }
];

// export const creditPricing = [
//     {
//         credit_title: "10k",
//         credits: "10000",
//         img: "/pricing_cards/cc1.png",
//         // img: "/credit_pricing1.png",
//         // img: "/c1.png",
//         price_title: "$100",
//         price: "100",
//     },
//     {
//         credit_title: "50k",
//         credits: "50000",
//         img: "/pricing_cards/cc2.png",
//         // img: "/credit_pricing2.png",
//         // img: "/c2.png",
//         price_title: "$400",
//         price: "400",
//     },
//     {
//         credit_title: "100k",
//         credits: "100000",
//         img: "/pricing_cards/cc3.png",
//         // img: "/credit_pricing3.png",
//         // img: "/c3.png",
//         price_title: "$750",
//         price: "750",
//     }
// ]

export const creditPricing = [
    // starter credit packages
    {
        credit_title: "100",
        credits: "100",
        img: "/pricing_cards/cc3.png",
        price_title: "$10",
        price: "10",
    },
    {
        credit_title: "400",
        credits: "400",
        img: "/pricing_cards/cc2.png",
        price_title: "$20",
        price: "20",
    },
    {
        credit_title: "700",
        credits: "700",
        img: "/pricing_cards/cc1.png",
        price_title: "$30",
        price: "30",
    },


    // main tier credit packages
    {
        credit_title: "5k",
        credits: "5000",
        img: "/pricing_cards/cc1.png",
        // img: "/credit_pricing1.png",
        // img: "/c1.png",
        price_title: "$100",
        price: "100",
    },
    {
        credit_title: "25k",
        credits: "25000",
        img: "/pricing_cards/cc2.png",
        // img: "/credit_pricing2.png",
        // img: "/c2.png",
        price_title: "$400",
        price: "400",
    },
    {
        credit_title: "50k",
        credits: "50000",
        img: "/pricing_cards/cc3.png",
        // img: "/credit_pricing3.png",
        // img: "/c3.png",
        price_title: "$750",
        price: "750",
    }
]

export const subscriptionPricing = [
    {
        subscription: "1",
        subscription_title: "1 Month",
        // img: "/sub_pricing1.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c1.png"
    },
    {
        subscription: "3",
        subscription_title: "3 Months",
        img: "/pricing_cards/c3.png"
    },
    {
        subscription: "6",
        subscription_title: "6 Months",
        // img: "/sub_pricing2.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c2.png"
    },
    // {
    //     subscription: "9",
    //     subscription_title: "9 Months",
    //     img: "/pricing_cards/c1.png"
    // },
    {
        subscription: "12",
        subscription_title: "12 Months",
        // img: "/sub_pricing3.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c3.png"
    }
]