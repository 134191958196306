import React, { useState, useEffect, useRef } from "react";
import { Link, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection, Avatar, User, Divider } from "@nextui-org/react";
import { useAuth } from "./context/auth_context";
import {Icon} from "@iconify/react";

export function ProfileAvatar() { 
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData
    } = useAuth()

    return (
        <>
            <Dropdown backdrop="blur">
                <DropdownTrigger>
                    <Avatar
                        isBordered
                        color="warning"
                        as="button"
                        size="sm"
                        className="transition-transform"
                        showFallback
                        name={signedIn && apiAccountData?.telegram_username ? (
                            apiAccountData.telegram_username.charAt(0).toUpperCase()
                        ) : null}
                        // src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                    />
                </DropdownTrigger>

                <DropdownMenu aria-label="Profile Actions" variant="flat">
                    <DropdownSection title="Account">
                        <DropdownItem 
                            key="profile" 
                            // className="h-14 gap-2" 
                            isReadOnly
                            startContent={<Icon icon="solar:smile-circle-outline" />}
                        >
                            <p className="text-md">{apiAccountData?.telegram_username}</p>
                            <Link className="text-md" href={`https://etherscan.io/address/${address}`} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'}>
                                {truncateAddress(address)}
                            </Link>
                            {/* <p className="text-md text-default-500">{apiAccountData.api_key}</p> */}
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection title="API Key">
                        <DropdownItem 
                            key="api_key" 
                            // className="h-14 gap-2" 
                            isReadOnly
                            startContent={<Icon icon="solar:key-outline" />}
                        >
                            <p className="text-md">{apiAccountData.api_key}</p>
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection title="Account Type">
                        <DropdownItem 
                            key="account_type" 
                            isReadOnly
                            startContent={<Icon icon="solar:medal-ribbons-star-outline" />}
                        >
                            <p className="text-md">{apiAccountData?.account_type ? apiAccountData.account_type.charAt(0).toUpperCase() + apiAccountData.account_type.slice(1) : 'Unknown'}</p>
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection title="Credits">
                        <DropdownItem 
                            key="credits" 
                            isReadOnly 
                            // showDivider
                            startContent={<Icon icon="solar:wallet-money-outline" />}
                        >
                            <p className="text-md">{apiAccountData?.credits ?? 0}</p>
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection title="Subscriptions">
                        <DropdownItem 
                            key="subscriptions" 
                            isReadOnly 
                            showDivider
                            startContent={<Icon icon="solar:card-2-outline" />}
                        >
                            {apiAccountData.active_subscriptions && Object.keys(apiAccountData.active_subscriptions).length > 0 ? (
                                Object.entries(apiAccountData.active_subscriptions).map(([namespace, data], index) => (
                                    // <div key={namespace} className="subscription-item">
                                    <div key={namespace} className={`subscription-item ${index !== 0 ? 'mt-2' : ''}`}>
                                        <p className="text-md">{namespace}</p>
                                        {data.endpoints.map((endpoint, index) => {
                                            const formattedDate = new Date(data.expiration).toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: '2-digit'
                                            });
                                            return (
                                                <p key={index} className="text-xs text-default-500">
                                                    {endpoint} <span className="text-red-500 text-xs">({formattedDate})</span>
                                                </p>
                                            );
                                        })}
                                    </div>
                                ))
                            ) : (
                                <p className="text-md">None</p>
                            )}
                        </DropdownItem>
                    </DropdownSection>
                    
                    {/* <DropdownItem key="analytics">
                        Analytics
                    </DropdownItem>

                    <DropdownItem key="system">System</DropdownItem>

                    <DropdownItem key="configurations">Configurations</DropdownItem>

                    <DropdownItem key="help_and_feedback">
                        Help & Feedback
                    </DropdownItem> */}
                    
                    <DropdownItem key="logout" className="text-danger" color="danger" onClick={() => handleSignOut()}>
                        Log Out
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}