import React, { useState, useEffect, useRef } from "react";
import { Link, Accordion, AccordionItem } from "@nextui-org/react";


export function Footer() {   
    return (
        <>
            <div>
                <div>
                    <p className="font-bold text-inherit">TOTO</p>
                    <p className="text-sm">An API for Crypto Twitter</p>
                    <p className="text-xs text-default-500">Made by <Link className="text-xs" href="https://oz.xyz" isExternal showAnchorIcon anchorIcon={' ↗'}>oz_dao</Link></p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-14">
                    <div className="mb-4 sm:mb-0">
                        <h2 className="font-bold mb-4 text-sm">Documentation</h2>
                        <ul className="space-y-4 text-default-500">
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app/authentication">
                                    Getting Started
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app/credit-values">
                                    Credit Values
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app/api-reference">
                                    API Reference
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">
                                    Issues
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-4 sm:mb-0">
                        <h2 className="font-bold mb-4 text-sm">Services</h2>
                        <ul className="space-y-4 text-default-500">
                            <li>
                                <Link className="text-sm text-default-500" href="/dashboard">
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" href="/playground">
                                    Playground
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" href="/pricing">
                                    Pricing
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" href="" isDisabled>
                                    Status
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-4 sm:mb-0">
                        <h2 className="font-bold mb-4 text-sm">Socials</h2>
                        <ul className="space-y-4 text-default-500">
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/toto_api">
                                    Twitter
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">
                                    Telegram
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app">
                                    Gitbook
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <h2 className="font-bold mb-4 text-sm">Other</h2>
                        <ul className="space-y-4 text-default-500">
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://oz.xyz">
                                    OzDAO
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://wandbot.app">
                                    WandBot
                                </Link>
                            </li>
                            <li>
                                <Link className="text-sm text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://ozkey.app">
                                    OzKey
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-14 text-sm">
                    {/* <p className="text-yellow-500">There's no place like home.</p> */}
                    <p className="text-[#0692d0]">There's no place like home.</p>
                </div>
            </div>
        </>
    );
}