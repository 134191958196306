import React, { useState, useEffect, useRef } from "react";

import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, ButtonGroup, Button, Tooltip, Spinner, Link, Input} from "@nextui-org/react";

import { useAuth } from "../context/auth_context";

// import { TelegramLoginWidgetTest } from "./telegram_login_widget_test";

export function ApiKeyCheck() {   
    const { address, playgroundApiKey, setPlaygroundApiKey, playgroundApiKeyValid, checkPlaygroundApiKey, resetPlaygroundApiKey } = useAuth();

    return (
        <>
            {/* <Card className="fixed inset-x-0 bottom-0 z-50" radius="none"> */}
            <Card radius="none" className="bg-background border-small border-divider">
                {/* <CardHeader>
                    <p className="text-xs text-default-500">Enter a Valid API Key to Make Requests</p>
                </CardHeader> */}
                <CardBody className="px-6">
                    <p className="text-xs text-default-500">Enter a Valid API Key to Make Requests</p>
                    
                    <div className="flex flex-row gap-4 mt-4">
                        <Input
                            type="password"
                            // label="Enter API Key"
                            variant="flat"
                            placeholder="1e1e1e1e1e1e1e1e"
                            // labelPlacement="outside"
                            isInvalid={playgroundApiKeyValid === false}
                            value={playgroundApiKey}
                            onChange={(e) => setPlaygroundApiKey(e.target.value)} 
                            errorMessage="Please enter a valid API key"
                            isDisabled={playgroundApiKeyValid === true}
                            // endContent={
                            //     <Button size="sm" color={apiKeyValid === true ? "success" : "primary"} onClick={checkApiKey} isDisabled={apiKeyValid === true}>
                            //         {apiKeyValid ? "Authorized!" : "Authorize"}
                            //     </Button>
                            // }
                            // size="md"
                            // className=""
                        />

                        <div>
                            <ButtonGroup>
                                <Button color={playgroundApiKeyValid === true ? "success" : "default"} onClick={checkPlaygroundApiKey} isDisabled={playgroundApiKeyValid === true}>
                                    {playgroundApiKeyValid ? "Authorized!" : "Authorize"}
                                </Button>
                                <Button onClick={resetPlaygroundApiKey}>Reset</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}