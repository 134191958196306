import React, { useState, useEffect, useRef, useMemo } from "react";
// import {Link} from "@nextui-org/link";

import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, getKeyValue} from "@nextui-org/react";
import {Card, CardHeader, CardBody, CardFooter, Divider, Button, Spinner} from "@nextui-org/react";

import { useDashboard } from "../context/dashboard_context";

export function RequestTable() {   
    const { fetchRequestTable, requestTableDataLoading, requestTableData } = useDashboard();

    // const [requestTableDataLoading, setRequestTableDataLoading] = useState(false)
    // const [requestTableData, setRequestTableData] = useState({})
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "access_time",
        direction: "descending",
    });

    useEffect(() => {
        fetchRequestTable()
    }, []);

    // useEffect(() => {
    //     setRequestTableDataLoading(true)

    //     fetch('/api/get_request_table_data', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ 
    //             // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' 
    //             // wallet_address: '0xd425333dd22b049f143522e519a06462c633f37a' // lattice
    //             // wallet_address: '0x741f19536d41c1ac8c20b8ff747aef34b24fdcd7'
    //             // wallet_address: '0x7ceacff6e1caecd461cec0f4fc21650674937a7a'
    //             // wallet_address: '0xbf4ebf8ef3e6b09996aef0ee9cc8f66db9a7a517'
    //             // wallet_address: '0x8a0120a7d28b2b2c32d3594ec94b1160dbb3ffc8'
    //             wallet_address: address
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data)
    //         if (data.data) {
    //             setRequestTableData(data.data)
    //         } else {
    //             console.log('no data...')
    //             setRequestTableData({})
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error verifying telegram username:', error);
    //         setRequestTableDataLoading(false)
    //     })
    //     .finally(() => {
    //         // setVerifyTelegramUsernameLoading(false)
    //         console.log('finished')
    //         setRequestTableDataLoading(false)
    //     });
    // }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { 
            year: '2-digit', 
            month: 'numeric', 
            day: 'numeric' 
        }).format(date);
    };

    const columns = [
        { key: "access_time", label: "ACCESS TIME", sortable: true },
        { key: "path", label: "PATH", sortable: true },
        { key: "method", label: "METHOD" },
        { key: "api_request", label: "PAYLOAD" },
    ];

    const rows = Object.keys(requestTableData).map(key => {
        const request = requestTableData[key];
        return {
            key,
            access_time: formatDate(request.access_time),
            full_access_time: request.access_time,
            path: request.path,
            method: request.method,
            api_request: request.api_request,
        };
    });

    const sortedItems = useMemo(() => {
        return [...rows].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, rows]);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return sortedItems.slice(start, end);
    }, [page, sortedItems, rowsPerPage]);

    const renderCell = (row, columnKey) => {
        const cellValue = getKeyValue(row, columnKey);

        switch (columnKey) {
            case "api_request":
                return <pre className="text-xs text-default-500">{cellValue}</pre>;
            default:
                return <p className="text-xs text-default-500">{cellValue}</p>;
        }
    };

    const onNextPage = () => {
        setPage((prev) => (prev < Math.ceil(rows.length / rowsPerPage) ? prev + 1 : prev));
    };

    const onPreviousPage = () => {
        setPage((prev) => (prev > 1 ? prev - 1 : prev));
    };
    
    return (
        <>
            <div className="mt-4">
                <Card className="bg-background border-small border-divider">
                    <CardHeader>
                        <p>Request History</p>
                    </CardHeader>
                    <CardBody>
                        {requestTableDataLoading ? (
                            <div className="flex justify-center items-center">
                                <Spinner />
                            </div>
                        ) : (
                            <Table
                                aria-label="Request Data Table"
                                // isStriped
                                removeWrapper
                                sortDescriptor={sortDescriptor}
                                onSortChange={setSortDescriptor}
                            >
                                <TableHeader columns={columns}>
                                    {column => (
                                        <TableColumn 
                                            key={column.key} 
                                            allowsSorting={column.sortable}
                                        >
                                            {column.label}
                                        </TableColumn>
                                    )}
                                </TableHeader>
                                <TableBody emptyContent={"No rows to display."} items={items}>
                                    {row => (
                                        <TableRow key={row.key}>
                                            {columnKey => (
                                                <TableCell>{renderCell(row, columnKey)}</TableCell>
                                            )}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </CardBody>
                    {Object.keys(requestTableData).length > 0 && (
                        <CardFooter className="flex flex-row mt-4 gap-4 justify-between">
                            <Pagination
                                total={Math.ceil(rows.length / rowsPerPage)}
                                color="primary"
                                page={page}
                                onChange={setPage}
                                className="justify-start"
                                size="sm"
                                // showControls
                            />
                            <div className="flex gap-2 justify-end">
                                <Button
                                    size="sm"
                                    // variant="flat"
                                    color="default"
                                    onPress={onPreviousPage}
                                    isDisabled={page === 1}
                                >
                                    Previous
                                </Button>
                                <Button
                                    size="sm"
                                    // variant="flat"
                                    color="default"
                                    onPress={onNextPage}
                                    isDisabled={page === Math.ceil(rows.length / rowsPerPage)}
                                >
                                    Next
                                </Button>
                            </div>
                        </CardFooter>
                    )}
                </Card>
            </div>
        </>
    );
}