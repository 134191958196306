import React, { useState, useEffect } from "react";
import { Button, Link } from "@nextui-org/react";

import { useAuth } from "../context/auth_context";

export function RequestOzAllocationButton() {
    const { 
        signedIn,
        requestOzAllocation, requestOzAllocationLoading, setRequestOzAllocationLoading
    } = useAuth();

    return (
        <>
            {signedIn ? (
                <Button color="primary" size="sm" isLoading={requestOzAllocationLoading} isDisabled={requestOzAllocationLoading} onClick={() => requestOzAllocation()}>{requestOzAllocationLoading ? null : "Request Allocation"}</Button>
            ) : (
                <Button color="primary" size="sm" as={Link} href="/login">Sign In</Button>
            )}
        </>
    );
}