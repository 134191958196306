import React, { useState, useEffect } from "react";
import { Button, Input, Divider, Link } from "@nextui-org/react";

import { useAuth } from "../context/auth_context";

export function RequestTrialAccountButton() {
    const { 
        requestTrialAccount,
        requestTrialAccountLoading,

        redeemTrialAccount,
        redeemTrialAccountLoading,
        redeemTrialAccountCode,
        setRedeemTrialAccountCode
    } = useAuth();

    return (
        <>
            <Button color="primary" size="sm" isLoading={requestTrialAccountLoading} isDisabled={requestTrialAccountLoading} onClick={() => requestTrialAccount()}>{requestTrialAccountLoading ? null : "Request Trial Account"}</Button>
            <p className="text-xs text-default-500 mt-4">*Subject to advanced screening to prevent abuse.</p>
            <p className="text-xs text-yellow-500">YOU WILL NOT BE ABLE TO ACTIVATE A TRIAL ACCOUNT WITH A FRESH WALLET</p>
        </>
    );
}