import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Divider, Spinner, Link, Snippet} from "@nextui-org/react";

import { useAuth } from "../context/auth_context";
import { useDashboard } from "../context/dashboard_context";

export function MainStatCards() {   
    const {
        signedIn,
        apiAccountData,
    } = useAuth()
    const { fetchMainStatCards, statCardLoading, statCardData } = useDashboard();

    // const [statCardLoading, setStatCardLoading] = useState(false)
    // const [statCardData, setStatCardData] = useState(null)

    useEffect(() => {
        fetchMainStatCards()
    }, []);
    
    return (
        <>
            <Card className="bg-background border-small border-divider w-full">
                <CardHeader>
                    <p className="">API Key</p>
                </CardHeader>
                <CardBody>
                    {apiAccountData.api_key ? (
                        <Snippet
                            className="w-full bg-background p-0"
                            // symbol="API Key:"
                            symbol=""
                            codeString={`${apiAccountData.api_key}`}
                        >
                            <span className="whitespace-pre-wrap break-words break-all">
                                {`${apiAccountData.api_key}`}
                            </span>
                        </Snippet>
                    ) : (
                        <p className="">N/A</p>
                    )}
                </CardBody>
            </Card>

            <Card className="bg-background border-small border-divider mt-4">
                <CardHeader>
                    <p>Subscriptions</p>
                </CardHeader>
                <CardBody>
                    {statCardLoading ? (
                        <Spinner />
                    ) : statCardData?.active_subscriptions && Object.keys(statCardData.active_subscriptions).length > 0 ? (
                        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {Object.entries(statCardData.active_subscriptions).map(([namespace, data]) =>
                                data.endpoints.map((endpoint, idx) => {
                                    const formattedDate = new Date(data.expiration).toLocaleDateString('en-US', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit'
                                    });
                                    return (
                                        <Card key={`${namespace}-${endpoint}-${idx}`} className="bg-background border-small border-divider col-span-full sm:col-span-1">
                                            <CardHeader>
                                                <p className="text-red-500">{formattedDate}</p>
                                            </CardHeader>
                                            <CardBody>
                                                <p>
                                                    <span className="text-default-500">{namespace.toUpperCase()}</span>/{endpoint}
                                                </p>
                                            </CardBody>
                                        </Card>
                                    );
                                })
                            )}
                        </div>
                    ) : (
                        <p className="text-3xl text-center">No Subscriptions</p>
                    )}
                </CardBody>
            </Card>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                <Card className="bg-background border-small border-divider">
                    <CardHeader>
                        <p>Credits Remaining</p>
                    </CardHeader>
                    <CardBody>
                        {statCardLoading ? (
                            <Spinner />
                        ) : (
                            <p className="text-3xl text-center">{statCardData?.credits_remaining}</p>
                        )}
                    </CardBody>
                    <CardFooter>
                        <span className="text-xs text-default-500">Running low? Check out our <Link className="text-xs" href="/pricing">pricing</Link>.</span>
                    </CardFooter>
                </Card>

                <Card className="bg-background border-small border-divider">
                    <CardHeader>
                        <p>Total Requests</p>
                    </CardHeader>
                    <CardBody>
                        {statCardLoading ? (
                            <Spinner />
                        ) : (
                            <p className="text-3xl text-center">{statCardData?.total_num_calls}</p>
                        )}
                    </CardBody>
                    {/* <CardFooter>
                        <span className="text-xs text-default-500">Running low? Check out our <Link className="text-xs" href="/pricing">pricing</Link>.</span>
                    </CardFooter> */}
                </Card>

                <Card className="bg-background border-small border-divider">
                    <CardHeader>
                        <p>Rows of Data Served</p>
                    </CardHeader>
                    <CardBody>
                        {statCardLoading ? (
                            <Spinner />
                        ) : (
                            <p className="text-3xl text-center">{statCardData?.total_num_rows_served}</p>
                        )}
                    </CardBody>
                    {/* <CardFooter>
                        <span className="text-xs text-default-500">Running low? Check out our <Link className="text-xs" href="/pricing">pricing</Link>.</span>
                    </CardFooter> */}
                </Card>

                <Card className="bg-background border-small border-divider">
                    <CardHeader>
                        <p>Days Active</p>
                    </CardHeader>
                    <CardBody>
                        {statCardLoading ? (
                            <Spinner />
                        ) : (
                            <p className="text-3xl text-center">{statCardData?.total_days_active}</p>
                        )}
                    </CardBody>
                    {/* <CardFooter>
                        <span className="text-xs text-default-500">Running low? Check out our <Link className="text-xs" href="/pricing">pricing</Link>.</span>
                    </CardFooter> */}
                </Card>
            </div>
        </>
    );
}