import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, Button, Card, CardHeader, CardBody, CardFooter, Tabs, Tab, Divider } from "@nextui-org/react";

import { useAuth } from "../components/context/auth_context";
import { Sidebar } from "../components/sidebar";
import { MainStatCards } from "../components/user_dashboard/main_stat_cards";
import { TransactionHistory } from "../components/user_dashboard/transaction_history";
import { ReBar } from "../components/user_dashboard/bar_chart";
import { ReRadar } from "../components/user_dashboard/radar_chart";
import { ReTreemap } from "../components/user_dashboard/treemap_chart";
import { RequestTable } from "../components/user_dashboard/request_table";

export function Dashboard() {  
    const navigate = useNavigate();
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData
    } = useAuth()
    
    return (
        <>
            <Sidebar>
                <div className="p-6">
                    {signedIn ? (
                        <>
                            {apiAccountData.is_trial && (
                                <Card className="bg-yellow-500 text-black">
                                    <CardHeader className=" flex flex-col justify-start items-start ">
                                        <span className="font-bold">
                                            Trial Account Detected!
                                        </span>

                                        <p>
                                            Consider purchasing a <Link className="" href="/pricing">credit/subscription package</Link> in order to make more requests.
                                        </p>
                                    </CardHeader>
                                </Card>
                            )}
            
                            <div className={apiAccountData.is_trial ? "mt-4" : ""}>
                                <MainStatCards />
                            </div>
                            <div className="mt-4">
                                <TransactionHistory />
                            </div>
                            <ReBar />
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
                                <ReRadar />
                                <ReTreemap />
                            </div>
                            <RequestTable />
                        </>
                    ) : (
                        'Please sign in to see your dashboard.'
                        
                        // // blurred card with text/button overlay in center
                        // <div className="relative">
                        //     <Card className="filter blur-sm">
                        //         <CardHeader>
                        //             <h3>Header Content</h3>
                        //         </CardHeader>
                        //         <CardBody>
                        //             <p>Body Content</p>
                        //         </CardBody>
                        //         <CardFooter>
                        //             <p>Footer Content</p>
                        //         </CardFooter>
                        //     </Card>
                        //     <div className="absolute inset-0 flex items-center justify-center">
                        //         {/* <h1 className="text-4xl font-bold text-white">Coming Soon</h1> */}
                        //         <Button>Login</Button>
                        //     </div>
                        // </div>
                    )}
                </div>
            </Sidebar>
        </>
    );
}