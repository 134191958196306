import React, { useState, useEffect } from "react";
import { Button, Input, Divider, Link, Textarea } from "@nextui-org/react";

import { useAuth } from "../context/auth_context";

export function SubscriptionContactForm() {
    const { 
        signedIn,
        submitScf,
        scfLoading, setScfLoading,
        scfTelegramInput, setScfTelegramInput,
        scfTextarea, setScfTextarea,
        scfCompanyName, setScfCompanyName,
    } = useAuth();

    return (
        <>
            <div className="max-w-lg mx-auto">
                <p className="text-lg">Interested in our subscription plans, enterprise services, or other offerings?</p>
                <p className="text-lg">Contact us today, and we'll get back to you as soon as possible!</p>

                {!signedIn && (
                    <Input className="mt-4" type="text" label="Telegram Username" variant="bordered" isRequired value={scfTelegramInput} onChange={(e) => setScfTelegramInput(e.target.value)} />
                )}
                <Input className={!signedIn ? `mt-2` : `mt-4`} type="text" label="Company Name" variant="bordered" isRequired value={scfCompanyName} onChange={(e) => setScfCompanyName(e.target.value)} />
                <Textarea
                    isRequired
                    label="Reason"
                    placeholder="Reaching out to learn more about..."
                    className="mt-2"
                    variant="bordered"
                    value={scfTextarea}
                    onChange={(e) => setScfTextarea(e.target.value)}
                />
                <div className="flex justify-end mt-2">
                    <Button color="primary" size="sm" isLoading={scfLoading} isDisabled={scfLoading} onClick={() => submitScf()}>{scfLoading ? null : "Submit"}</Button>
                </div>
            </div>
        </>
    );
}