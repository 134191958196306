import React, { useState, useEffect, useRef } from "react";

import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Tooltip, CheckboxGroup, Checkbox, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Link, RadioGroup, Radio} from "@nextui-org/react";

import { useAuth } from "../context/auth_context";
import { useAlert } from "../context/alert_context";

import { creditPricing } from "../constants/api_endpoint_info";

export function CreditPackage() {   
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        requestTrialAccount,
        requestTrialAccountLoading,
        handleCreditPurchase,
        creditPurchaseLoading
    } = useAuth()
    const { showToast } = useAlert();

    const [featureSelected, setFeatureSelected] = useState(["one", "two", "three"]);

    const [creditState, setCreditState] = useState({
        selectedCredits: null,
        selectedCreditsTitle: null,
        selectedPrice: null,
        selectedPriceTitle: null,
        paymentType: "usdc"
    });

    const handleBuyClick = (item) => {
        console.log(item);
        // setCreditState({
        //     selectedCredits: item.credits,
        //     selectedCreditsTitle: item.credit_title,
        //     selectedPrice: item.price,
        //     selectedPriceTitle: item.price_title,
        //     paymentType: creditState.paymentType
        // });
        // onOpen();
        const updatedItem = {
            ...item,
            payment_type: creditState.paymentType,
            package_type: 'credit'
        };
        handleCreditPurchase(updatedItem)
    };

    useEffect(() => {
        console.log(creditState);
    }, [creditState]);

    return (
        <>
            <p className="text-5xl">Credit Packages</p>
            <p className="text-sm text-default-500">Starting at $100</p>

            <CheckboxGroup
                label="Features"
                // defaultValue={["one", "two", "three"]}
                value={featureSelected}
                onValueChange={setFeatureSelected}
                className="mt-10"
            >
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="one">No expiration</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="two">Pay-as-you-go pricing</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="three"><Link className="" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">Public forum support</Link></Checkbox>
            </CheckboxGroup>

            <div className="mt-10">
                <RadioGroup
                    label="Payment Method"
                    value={creditState.paymentType}
                    onValueChange={(newPaymentType) => setCreditState(prevState => ({ ...prevState, paymentType: newPaymentType }))}
                    orientation="horizontal"
                    size="sm"
                    color="success"
                >
                    {/* <Radio value="ethereum">Ethereum</Radio> */}
                    <Radio value="usdc">USDC</Radio>
                    <Radio value="usdt">USDT</Radio>
                </RadioGroup>
            </div>

            <div className="gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mt-10">
                {creditPricing.map((item, index) => (
                    <Card 
                        shadow="sm" 
                        key={index} 
                        // isPressable 
                        // onClick={onOpen}
                        // isFooterBlurred
                        className="h-[140px] sm:flex-grow"
                        style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                        >
                        <CardHeader className="flex flex-col items-start absolute">
                            <h4 className="text-white font-medium text-5xl">{item.credit_title}<span className="text-2xl text-white opacity-50">({item.price_title})</span></h4>
                            {/* <p className="text-default-500 text-2xl">
                                {item.price_title}
                            </p> */}
                        </CardHeader>
                        <CardBody>
                        </CardBody>
                        <CardFooter>
                            {signedIn ? (
                                <Button 
                                    size="sm" 
                                    onClick={() => handleBuyClick(item)}
                                    isDisabled={creditPurchaseLoading}
                                    isLoading={creditPurchaseLoading}
                                >
                                    {creditPurchaseLoading ? null : "Purchase"}
                                </Button>
                            ) : (
                                <Button 
                                    as={Link}
                                    href="/login"
                                    size="sm" 
                                >
                                    Login
                                </Button>
                            )}
                        </CardFooter>
                    </Card>
                ))}
            </div> 

            {/* <Modal 
                isOpen={isOpen} 
                onOpenChange={onOpenChange}
                // placement="top-center"
                backdrop="blur"
                isDismissable={false}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Login
                                <p className="text-xs text-default-500">
                                    Read the <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/setting-up-wandbot">documentation</Link> for more detailed steps.
                                </p>
                            </ModalHeader>
                            <ModalBody>
                                {creditState.selectedCredits && (
                                    <>
                                        <p>Credits: {creditState.selectedCredits}</p>
                                        <p>Credits Title: {creditState.selectedCreditsTitle}</p>
                                        <p>Price: ${creditState.selectedPrice}</p>
                                        <p>Price Title: {creditState.selectedPriceTitle}</p>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                footer
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal> */}
        </>
    );
}