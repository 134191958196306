import React, { useState, useEffect, useRef } from "react";
import { Link, Accordion, AccordionItem } from "@nextui-org/react";


export function Faq() {   
    return (
        <>
            <Accordion variant="light" isCompact defaultExpandedKeys={["1"]}>
                <AccordionItem key="1" aria-label="Accordion 1" title="Where can I find the documentation?" className="text-default-500">
                    You can access our documentation <Link isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app/">here</Link>.
                </AccordionItem>
                <AccordionItem key="2" aria-label="Accordion 1" title="What payment methods do you accept?" className="text-default-500">
                    We currently only accept USDC and USDT via onchain payments through the Ethereum blockchain. Other options such as Coinbase Commerce and Stripe are being looked into.
                </AccordionItem>
                <AccordionItem key="3" aria-label="Accordion 3" title="What plans do you offer?" className="text-default-500">
                    We offer select credit and subscription packages. You can find the details of all our plans on our <Link href="/pricing">pricing</Link> page. For inquiries regarding custom endpoints, enterprise solutions, etc, please contact us <Link isExternal href="https://t.me/babushka_slayer" showAnchorIcon anchorIcon={' ↗'}>here</Link>.
                </AccordionItem>
                {/* <AccordionItem key="4" aria-label="Accordion 2" title="Why do I need to connect my telegram account?" className="text-default-500">
                    We use telegram as part of the authentication system when creating API accounts. Additionally it is our preferred medium for contact.
                    We use it purely for log in and contact and have no ability to access private data or perform any action using your account.
                    (change this up - copy and pasted it from https://web3alerts.app/)
                </AccordionItem> */}
                <AccordionItem key="4" aria-label="Accordion 4" title="Can you create a custom endpoint for my usecase?" className="text-default-500">
                    Yes! contact <Link isExternal href="https://t.me/babushka_slayer" showAnchorIcon anchorIcon={' ↗'}>babushka_slayer</Link> on Telegram and describe what you are looking for.
                </AccordionItem>
                <AccordionItem key="5" aria-label="Accordion 3" title="Do you offer a free trial?" className="text-default-500">
                    Yes, we offer a limited free trial. See the <Link href="/pricing">pricing</Link> page for more details.
                </AccordionItem>
                <AccordionItem key="6" aria-label="Accordion 3" title="I'm a student that needs Twitter data, can you help?" className="text-default-500">
                    Yes! We will work with academics who have a valid usecase. Contact <Link isExternal href="https://t.me/babushka_slayer" showAnchorIcon anchorIcon={' ↗'}>babushka_slayer</Link> on Telegram.
                </AccordionItem>
                <AccordionItem key="7" aria-label="Accordion 5" title="What is the refund policy?" className="text-default-500">
                    We do not offer refunds. 
                    {/* However, bought credits will never expire so if you wish to return, your credits will be there for you to use. */}
                </AccordionItem>
                <AccordionItem key="8" aria-label="Accordion 5" title="Do my credits expire?" className="text-default-500">
                    No, credits will never expire.
                </AccordionItem>
                <AccordionItem key="9" aria-label="Accordion 5" title="Who made TOTO?" className="text-default-500">
                    The development team at <Link isExternal href="https://twitter.com/oz_dao" showAnchorIcon anchorIcon={' ↗'}>OzDAO</Link>.
                </AccordionItem>
            </Accordion>

            <p className="text-xs text-default-500 mt-4">Unable to find your question here? Contact <Link isExternal href="https://t.me/babushka_slayer" className="text-xs" showAnchorIcon anchorIcon={' ↗'}>babushka_slayer</Link> on Telegram</p>
        </>
    );
}