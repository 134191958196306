import React, { createContext, useContext, useCallback, useState, useRef } from "react";
import { Toaster, toast } from 'sonner';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const showToast = ({ type = 'default', message, position = 'bottom-center' }) => {
        switch (type) {
            case 'success':
                toast.success(message, { position });
                break;
            case 'error':
                toast.error(message, { position });
                break;
            case 'loading':
                toast.loading(message, { position });
                break;
            default:
                toast(message, { position });
                break;
        }
    };

    return (
        <AlertContext.Provider value={{ showToast }}>
            {children}
            <Toaster theme="dark" />
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
